import data from '../data/new_strapi.json';

const useMarketingPopupVariant = () => {
  const marketingPopupVariantData =
    data.new_strapi.marketing_popup_variant.data || [];

  const sanitizedData = marketingPopupVariantData.map(
    (marketingPopupVariant) => {
      const { description, title, variantId, CTA } =
        marketingPopupVariant.attributes || {};

      return {
        description,
        title,
        variantId,
        CTA,
      };
    },
  );

  const getVariantDataByVariantId = (variantId) => {
    return sanitizedData.find((variant) => variant.variantId === variantId);
  };

  return { getVariantDataByVariantId };
};

export default useMarketingPopupVariant;
