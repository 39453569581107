import React, { useContext, useEffect, useState } from 'react';
import FormDropDown from '../FormComponents/FormDropDown';
import {
  generateDropDownValues,
  profiles,
  getGraduationYearList,
  leadStage,
  experiments,
  getDemoSlotsMenuItems,
  getTwoNextDemoSlots,
  allotExperimentId,
  whitelistedLeadSources,
  ERROR_LEAD_SOURCE,
  highestQualifications,
  jobDomains,
  jobRoleTechs,
  jobRoleNonTechs,
  companies,
  LEAD_GEN_DATA_SOURCE,
  extendLeadObjectWithUserLocationData,
  LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS,
  workExperience,
  LEAD_GEN_QA_AUTOMATION_PATH,
  LEAD_GEN_AUTOMATION_EDGE_PATH,
  LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS,
  OTHER_DEMO_SLOT,
  routesWithCustomDemoSlots,
} from '../../../constants/LeadGenerationConstants/index';
import FormInput from '../FormComponents/FormInput';
import { FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormStateContext } from '../../../context/FormContextProvider';
import LeadGenerationService from '../../../../utils/lead-generation';
import FormSubmitButton from '../FormComponents/FormSubmitButton';
import toast from 'react-hot-toast';
import {
  faBook,
  faBuilding,
  faBriefcase,
  faCalendarDays,
} from '@fortawesome/free-solid-svg-icons';
import { faUserTie } from '@fortawesome/pro-solid-svg-icons';

import * as Sentry from '@sentry/gatsby';
import { GTM } from '../../../analytics/gtm';
import { gtmEvents } from '../../../analytics/gtmEventsConstant';
import { useLocation } from '@reach/router';
import {
  LEAD_GEN_PROGRAM_ID_FULL_STACK,
  LEAD_GEN_UTM_CAMPAIGN_TWO,
  LEAD_GEN_PROGRAM_ID_QA,
} from '../../../constants/LeadGenerationConstants/index';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import useResizer from '@components/extra/useResizer';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import {
  LEAD_GEN_UTM_SOURCE_QA,
  LEAD_GEN_UTM_SOURCE_DEV,
} from '../../../constants/LeadGenerationConstants/index';
import { getPageTwoSchema } from '../FormPageValidations/PageTwoValidation';
import { addMinutes, compareAsc, format } from 'date-fns';
import { navigate } from 'gatsby';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import FormAutoComplete from '../FormComponents/FormAutoComplete';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';
import { demoSlotRedirectUrlBasedOnRoute } from '@src/utils/LeadGeneration/index';

export default function PageTwo(props) {
  const {
    closeDialog,
    programType,
    trialProgramId,
    buttonLocation,
    isOpenForm,
    demoSlots,
    pageTwoButtonText,
    applicationPage = '',
    isFullStackPpcVl,
    customUtmParams,
  } = props;

  // State variables
  const state = useContext(FormStateContext);

  const globalState = useContext(GlobalStateContext);
  const globalDispatch = useContext(GlobalDispatchContext);
  const [isFormTouched, setIsFormTouched] = useState(false); //state to track if a form field is focused
  const [loading, setLoading] = useState(false);
  const isMobile = useResizer();
  const email = globalState.email;
  const experiment = applicationPage ? '' : allotExperimentId(email);

  // Path variables
  const location = useLocation();
  const pathname = location?.pathname;

  const userLocation = globalState.userLocation;

  // UTM variables
  const storedParams = globalState.utm;
  const searchParam = new URLSearchParams(storedParams);
  const utmSource = searchParam.get('utm_source') || customUtmParams?.utmSource;
  const utmMedium = searchParam.get('utm_medium') || customUtmParams?.utmMedium;
  const utmPublisher = searchParam.get('utm_publisher');
  const utmCampaign =
    searchParam.get('utm_campaign') || customUtmParams?.utmCampaign;
  const utmTerm = searchParam.get('utm_term');
  const utmContent = searchParam.get('utm_content');
  const gclID = searchParam.get('gclid');
  const lcID = searchParam.get('li_fat_id');
  const fbclID = searchParam.get('fbclid');

  // Check if the current lead if of type QA
  const isMQALead =
    pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
    pathname === LEAD_GEN_AUTOMATION_EDGE_PATH ||
    programType === LEAD_GEN_PROGRAM_ID_QA;
  const isSde2Lead =
    pathname === '/software-development-fellowship-program-plus/';

  const { isDataSciencePage } = useDataScienceProgram();
  const isDemoSlotSelectionEnabled =
    routesWithCustomDemoSlots.includes(pathname);

  const getProgramIdRedirect = () => {
    if (isMQALead) return LEAD_GEN_PROGRAM_ID_QA;
    else if (isSde2Lead) return LEAD_GEN_PROGRAM_ID_FELLOWSHIP_PLUS;
    else if (isDataSciencePage) return LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS;
    return LEAD_GEN_PROGRAM_ID_FULL_STACK;
  };

  // Demo Slot variables
  const nextDemoSlots = getTwoNextDemoSlots(demoSlots);
  let demoSlotMenuItems = getDemoSlotsMenuItems(nextDemoSlots);

  if (isDemoSlotSelectionEnabled) {
    const formattedDemoSessionDate = format(new Date(), 'EEE, do LLL');

    demoSlotMenuItems = [
      ...demoSlotMenuItems,
      {
        label: <>Today - {formattedDemoSessionDate}: Any Other Time</>,
        value: OTHER_DEMO_SLOT,
      },
    ];
  }

  // Show demo field option only if lead is from demo flow
  const shouldShowDemoSlots =
    experiment === experiments.DEMO_FLOW &&
    nextDemoSlots &&
    Array.isArray(nextDemoSlots) &&
    nextDemoSlots.length > 0;

  // initialize RHF variables
  const hookFormTwo = useForm({
    defaultValues: {
      name: '',
      highestQualification: '',
      graduationYear: new Date().getFullYear(),
      ...(!isFullStackPpcVl && {
        currentlyWorking: '',
        jobDomain: '',
        jobRole: '',
        company: '',
      }),
      trialSlot: '',
    },
    resolver: yupResolver(
      getPageTwoSchema(
        true,
        shouldShowDemoSlots,
        isSde2Lead,
        isFullStackPpcVl,
        isDemoSlotSelectionEnabled,
      ),
    ),
    mode: 'all',
    shouldFocusError: false,
  });

  /* START: Focus on error field */
  const [canFocus, setCanFocus] = useState(true);
  const onError = () => setCanFocus(true);

  useEffect(() => {
    if (hookFormTwo.formState.errors && canFocus) {
      // Get all error fields
      const elements = Object.keys(hookFormTwo.formState.errors)
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);
      elements.sort(
        (a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top,
      );

      // Set scroll to the 1st one
      if (elements.length > 0) {
        let errorElement = elements[0];
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        errorElement.focus({ preventScroll: true });
        setCanFocus(false);
      }
    }
  }, [hookFormTwo.formState, canFocus]);
  /* END: Focus on error field */

  const currentlyWorkingWatcher = hookFormTwo.watch('currentlyWorking');
  const domainWatcher = hookFormTwo.watch('jobDomain');

  const isCurrentlyWorking = currentlyWorkingWatcher === 'Yes';
  const isDomainTech = domainWatcher === 'Tech';

  // Update lead-document of firebase and redirect to /registered page
  const submitPageTwo = async (formData) => {
    try {
      setLoading(true);

      const jobRole = [...jobRoleTechs, ...jobRoleNonTechs].includes(
        formData.jobRole,
      )
        ? formData.jobRole
        : 'Other';
      const company = companies.includes(formData.company)
        ? formData.company
        : 'Other';
      const highestQualification = highestQualifications.includes(
        formData.highestQualification,
      )
        ? formData.highestQualification
        : 'Other';

      let payload = {
        leadID: globalState.globalLeadDocumentId, // Retrieve profile and docID values from context

        name: formData.name,

        highestQualification: highestQualification,
        ...(highestQualification === 'Other'
          ? { highestQualificationOther: formData.highestQualification }
          : {}),

        graduationYear: formData.graduationYear,
        currentlyWorking: isFullStackPpcVl
          ? state.workingStatus
          : formData.currentlyWorking,
        dataSource: LEAD_GEN_DATA_SOURCE,

        ...(formData.currentlyWorking === 'Yes' && !isFullStackPpcVl
          ? { jobDomain: formData.jobDomain }
          : {}),

        ...(formData.currentlyWorking === 'Yes' && !isFullStackPpcVl
          ? { jobRole: jobRole }
          : {}),
        ...(formData.currentlyWorking === 'Yes' && jobRole === 'Other'
          ? { jobRoleOther: formData.jobRole }
          : {}),

        ...(formData.currentlyWorking === 'Yes' && !isFullStackPpcVl
          ? { currentCompany: company }
          : {}),
        ...(formData.currentlyWorking === 'Yes' &&
        company === 'Other' &&
        !isFullStackPpcVl
          ? { currentCompanyOther: formData.company }
          : {}),

        // Form Data for SDE2
        ...(formData.currentlyWorking === 'Yes' && isSde2Lead
          ? {
              techStack: formData.techStack,
              workExperience: formData.workExperience,
            }
          : {}),

        formURL: pathname,
        location: buttonLocation || '',
        formStep: LEAD_GEN_UTM_CAMPAIGN_TWO,
        program: isSde2Lead
          ? 'Software Development Plus'
          : isMQALead
          ? 'QA Automation'
          : isDataSciencePage
          ? 'Data Analytics'
          : 'Software Development',
        programID: trialProgramId
          ? trialProgramId
          : isMQALead
          ? LEAD_GEN_PROGRAM_ID_QA
          : isDataSciencePage
          ? LEAD_GEN_PROGRAM_ID_DATA_ANALYTICS
          : LEAD_GEN_PROGRAM_ID_FULL_STACK,
        applicationStage: leadStage.FULL_FILL,
        experiment: experiment,
      };

      // add UTM data
      if (utmSource) {
        if (whitelistedLeadSources.includes(utmSource))
          payload.utmSource = utmSource;
        else payload.utmSource = ERROR_LEAD_SOURCE;
      }
      if (utmMedium) payload.utmMedium = utmMedium;
      if (utmPublisher) payload.utmPublisher = utmPublisher;
      if (utmCampaign) payload.utmCampaign = utmCampaign;
      if (utmContent) payload.utmContent = utmContent;
      if (utmTerm) payload.utmTerm = utmTerm;
      if (gclID) payload.gclID = gclID;
      if (lcID) payload.lcID = lcID;
      if (fbclID) payload.fbclID = fbclID;

      // Add user location data
      extendLeadObjectWithUserLocationData(payload, userLocation);

      // Append selected slot details
      if (shouldShowDemoSlots && formData.trialSlot) {
        const selectedDemo = nextDemoSlots.find(
          (slot) =>
            compareAsc(
              new Date(slot.TrialTime),
              new Date(formData.trialSlot),
            ) === 0,
        );

        if (selectedDemo) {
          // (yyyy-MM-dd HH:mm:ss) format and conversion to IST as LeadSquared stores time in UTC which is 5:30 hours ahead
          const date = new Date(formData.trialSlot);
          payload.demoSlotStartTime = format(
            addMinutes(date, date.getTimezoneOffset()),
            'yyyy-MM-dd HH:mm:ss',
          );

          if (selectedDemo['MagicLink'])
            payload.demoSlotJoinUrl = selectedDemo['MagicLink'];

          if (selectedDemo['TrialType'])
            payload.demoSlotType = selectedDemo['TrialType'];
        }
      }

      await LeadGenerationService.updateLead(payload);
      setLoading(false);

      //If Accelerate Page & trial status is joinRightNow:
      if (
        isDemoSlotSelectionEnabled &&
        formData.trialSlot === OTHER_DEMO_SLOT
      ) {
        GTM.leadSquaredEvent(
          ELeadSquaredActivityCode.OTHER_DEMO_TRIAL_SLOT_SELECTION,
          email,
        );
      }

      // Send a GTM event for full filled form
      GTM.track(gtmEvents.LEAD_GEN_FULL_FORM_FILL, {
        url: location.href,
        email: state.email,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });
      // GA event for L2 successfull submission:
      GTM.track(gtmEvents.L2_SUCCESSFULL_SUBMISSION, {
        url: location.href,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
        applicationPage,
      });

      // Send a GTM event for CWP
      if (formData.currentlyWorking === 'Yes') {
        GTM.track(gtmEvents.CWP_EVENT, {
          url: location.href,
          email: state.email,
          location:
            pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
            pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
              ? LEAD_GEN_UTM_SOURCE_QA
              : LEAD_GEN_UTM_SOURCE_DEV,
          buttonLocation: buttonLocation,
          applicationPage,
        });
      }

      GTM.leadSquaredEvent(
        ELeadSquaredActivityCode.BOOK_FREE_TRIAL_SUBMITTED,
        state.email,
      );

      let parseUtmParams = `programID=${getProgramIdRedirect()}`;

      if (Boolean(globalState.utm)) {
        const tokenizeUtm = globalState.utm.split('&');
        tokenizeUtm.forEach((utm) => {
          if (
            !utm.startsWith('programID') &&
            !utm.startsWith('leadFlow') &&
            !utm.startsWith('leadform_open') &&
            !utm.startsWith('static_form')
          )
            parseUtmParams = parseUtmParams.concat(
              `&${utm.replaceAll('/', '')}`,
            );
        });
      }

      globalDispatch({
        type: 'SET_UTM',
        payload: parseUtmParams,
      });

      closeDialog();

      const defaultRedirect = `/registered/v2/?${parseUtmParams}${
        applicationPage ? `&page=${applicationPage}` : ''
      }`;

      if (
        isDemoSlotSelectionEnabled &&
        formData.trialSlot === OTHER_DEMO_SLOT
      ) {
        const redirectUrl = demoSlotRedirectUrlBasedOnRoute(pathname);
        navigate(redirectUrl, defaultRedirect);
      } else {
        navigate(defaultRedirect);
      }
    } catch (error) {
      setLoading(false);
      toast.error('Could not process request, please try again later.', {
        duration: 6000,
        style: { fontSize: '14px' },
        id: 'lead-gen-form-page-2',
      });

      // Log error to sentry
      Sentry.withScope(function (scope) {
        scope.setUser({ email: state.email });
        scope.setTag('lead_gen_form', 'critical');
        scope.setLevel('critical');
        Sentry.captureException(error);
      });

      // GA event for L2 unsuccessfull submission:
      GTM.track(gtmEvents.L2_UNSUCCESSFULL_SUBMISSION, {
        error: error?.message,
      });
    }
  };

  //handler function to detect when a field is focused and send the event to GA:
  const handleFocus = () => {
    if (!isFormTouched) {
      //only register one attempt for form filling:
      GTM.track(gtmEvents.L2_FORM_FILL_ATTEMPT, {
        url: location.href,
        location:
          pathname === LEAD_GEN_QA_AUTOMATION_PATH ||
          pathname === LEAD_GEN_AUTOMATION_EDGE_PATH
            ? LEAD_GEN_UTM_SOURCE_QA
            : LEAD_GEN_UTM_SOURCE_DEV,
        buttonLocation: buttonLocation,
      });
      setIsFormTouched(true);
    }
  };

  return (
    <FormProvider {...hookFormTwo}>
      <form
        autoComplete="on"
        className={`mt-2 flex w-full flex-col items-stretch gap-3.5 overflow-y-auto`}
        onSubmit={hookFormTwo.handleSubmit(submitPageTwo, onError)}
      >
        <div
          className={`mt-2 flex w-full flex-col items-stretch gap-3.5 overflow-y-auto scrollbar-hide ${
            isOpenForm ? 'overflow-y-auto' : ''
          }`}
        >
          <FormInput
            icon={<FontAwesomeIcon icon={faAddressCard} />}
            name="name"
            label="Name"
            isOpenForm={isOpenForm}
            handleFocus={handleFocus}
          />

          <FormAutoComplete
            icon={<FontAwesomeIcon icon={faBook} />}
            menu_items={generateDropDownValues(highestQualifications)}
            name="highestQualification"
            label="Education Qualification (Eg. BTech)"
            isOpenForm={isOpenForm}
            handleFocus={handleFocus}
          />

          <Box
            className={`grid ${
              isMobile
                ? 'gap-3.5'
                : isFullStackPpcVl
                ? 'grid-cols-[auto]'
                : 'grid-cols-[45%_auto] gap-x-3'
            }`}
          >
            <FormDropDown
              icon={<FontAwesomeIcon icon="fa-solid fa-graduation-cap" />}
              name="graduationYear"
              label="Graduation Year"
              menu_items={getGraduationYearList()}
              isOpenForm={isOpenForm}
              handleFocus={handleFocus}
            />
            {!isFullStackPpcVl && (
              <FormDropDown
                icon={<FontAwesomeIcon icon={faBriefcase} />}
                name="currentlyWorking"
                label="Are you working?"
                menu_items={generateDropDownValues(profiles)}
                isOpenForm={isOpenForm}
                handleFocus={handleFocus}
              />
            )}
          </Box>

          {isCurrentlyWorking && (
            <FormDropDown
              icon={<FontAwesomeIcon icon={faBriefcase} />}
              menu_items={generateDropDownValues(jobDomains)}
              name="jobDomain"
              label="Job Domain"
              isOpenForm={isOpenForm}
            />
          )}

          {isCurrentlyWorking && domainWatcher && (
            <FormAutoComplete
              icon={<FontAwesomeIcon icon={faBriefcase} />}
              menu_items={
                isDomainTech
                  ? generateDropDownValues(jobRoleTechs)
                  : generateDropDownValues(jobRoleNonTechs)
              }
              name="jobRole"
              label="Job Role"
              isOpenForm={isOpenForm}
            />
          )}

          {isCurrentlyWorking && (
            <FormAutoComplete
              icon={<FontAwesomeIcon icon={faBuilding} />}
              label="Company"
              name="company"
              menu_items={generateDropDownValues(companies)}
              isOpenForm={isOpenForm}
            />
          )}

          {isCurrentlyWorking && isSde2Lead && (
            <FormDropDown
              icon={<FontAwesomeIcon icon={faUserTie} />}
              menu_items={generateDropDownValues(workExperience)}
              name="workExperience"
              label="Work experience in software development"
              isOpenForm={isOpenForm}
            />
          )}

          {isCurrentlyWorking && isSde2Lead && (
            <FormInput
              icon={<FontAwesomeIcon icon={faBriefcase} />}
              name="techStack"
              label="Tech stack you work on"
              isOpenForm={isOpenForm}
            />
          )}

          {shouldShowDemoSlots && (
            <FormDropDown
              icon={<FontAwesomeIcon icon={faCalendarDays} />}
              label="Trial Workshop Slot"
              menu_items={demoSlotMenuItems}
              name="trialSlot"
              isOpenForm={isOpenForm}
              handleFocus={handleFocus}
            />
          )}
        </div>
        <FormSubmitButton
          text={pageTwoButtonText ? pageTwoButtonText : 'Book Your Trial'}
          loading={loading}
          className={isOpenForm ? `flex w-full` : ''}
        />
      </form>
    </FormProvider>
  );
}
