import { countryCodes } from '../constants/country-codes/index.js';

export function timeToMinutes(timeString) {
  // Split the time string into hours, minutes, seconds, and milliseconds
  const [hours, minutes, seconds] = timeString.split(':').map(parseFloat);

  // Calculate the total minutes
  const totalMinutes = hours * 60 + minutes + seconds / 60;

  return totalMinutes;
}

export const getUniqueId = function () {
  let uniqueId = localStorage.getItem('user_unique_id');

  if (!uniqueId) {
    uniqueId = Date.now().toString(36) + Math.random().toString(36).substr(2);
    localStorage.setItem('user_unique_id', uniqueId);
  }

  return uniqueId;
};

export const getQueryParams = (url) => {
  let queryParams = {};
  let queryString = url.split('?')[1]; // Get the query string part of the URL
  if (queryString) {
    let pairs = queryString.split('&'); // Split the query string into individual key-value pairs
    pairs.forEach((pair) => {
      let [key, value] = pair.split('='); // Split each key-value pair into separate key and value
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value); // Decode and add to the queryParams object
    });
  }
  return queryParams;
};

export const getCountryCodeAndPhone = (phone) => {
  let countryCode = '';
  let phoneNum = '';
  // Iterate through country codes to find the matching prefix
  for (const code of countryCodes) {
    if (phone.startsWith(code)) {
      countryCode = code.substring(1);
      phoneNum = phone.substring(code.length);
      break;
    }
  }

  return { countryCode, phoneNum };
};

export const getSessionStartDate = (isAccelerateJoinSessionPage) => {
  const day = new Date().getDay();

  let currentDate = new Date();

  if (day === 6 || day === 0) {
    currentDate.setHours(14, 0, 0, 0);
  } else if (isAccelerateJoinSessionPage) {
    currentDate.setHours(19, 0, 0, 0);
  } else {
    currentDate.setHours(20, 30, 0, 0);
  }

  return new Date(currentDate);
};
