const React = require('react');

const EProgramIDs = {
  FREE_TRIAL: 'FREE_TRIAL',
  BACKEND_DEVELOPER_EXPERIENCE: 'BACKEND_DEVELOPER_EXPERIENCE',
  FULL_STACK: 'FULL_STACK',
  FELLOWSHIP: 'FELLOWSHIP',
  QA_AUTOMATION_TESTING: 'QA_AUTOMATION_TESTING',
  MASTERS_PROGRAM: 'MASTERS_PROGRAM',
  LAUNCH: 'LAUNCH',
  DATA_SCIENCE: 'DATA_SCIENCE',
  SYSTEM_DESIGN: 'SYSTEM_DESIGN',
};

const EPrograms = {
  [EProgramIDs.FULL_STACK]: {
    title: 'Full Stack Developer Program',
    description:
      'Work on professional projects like developers at AirBnB, Amazon, Netflix, with mentorship, to master full-stack development.',
    duration: '6 Months',
    highlights: [
      {
        title: '7 Professional Projects to learn with real work-experience',
        description: 'Learn React, HTML, CSS, JavaScript, MERN and more',
      },
      {
        title: '15+ hands-on micro-skilling exercises & workshops',
        description:
          'Learn a variety of tools, CS concepts and practical developer skills',
      },
    ],
  },
  [EProgramIDs.BACKEND_DEVELOPER_EXPERIENCE]: {
    title: 'Backend Developer Program',
    description: (
      <>
        Build projects like developers at Zomato, Google, Zerodha, with
        mentorship, to learn backend development deeply.
      </>
    ),
    duration: '6 Months',
    highlights: [
      {
        title: '5 Professional projects to learn with real work-experience',
        description:
          'Learn Java, Springboot, RestAPI, MongoDB, Caching, and more',
      },
      {
        title: '15+ hands-on micro-skilling exercises & workshops',
        description:
          'Learn a variety of tools, CS concepts and practical developer skills',
      },
    ],
  },
  [EProgramIDs.FELLOWSHIP]: {
    title: 'Fellowship Program in Software Development',
    description:
      'Learn with real work experience and get assured referrals to transition into a Full-Stack or Backend Developer at product-based companies',
    duration: '9 Months',
    highlights: [
      {
        title: 'Project-led MERN or Backend Specialisation',
      },
      {
        title: 'DS, Algo, and System Design curriculum',
      },
      {
        title: 'Externships with Real Tech Companies',
      },
      {
        title: 'Assured referrals in top dev roles',
      },
    ],
    specializations: [
      {
        title: 'Full Stack Specialisation',
        content: '7 Professional Projects to learn with real work-experience',
        tech: '(MongoDB, Express, React, NodeJS)',
      },
      {
        title: 'Backend Specialisation',
        content: '5 Professional projects to learn with real work-experience',
        tech: '(Core Java and Spring Boot )',
      },
    ],
    deliverables: [
      {
        title: 'Trial Session',
        description: <div>Free</div>,
      },
      {
        title: 'Duration',
        description: <div>9 months</div>,
      },
      {
        title: 'Scholarships',
        description: <div className="text-center">Assured Scholarships</div>,
      },
      {
        title: 'Career Services',
        description: <div className="text-center">Assured Referrals</div>,
      },
    ],
  },
  [EProgramIDs.QA_AUTOMATION_TESTING]: {
    title: 'Fellowship Program in QA Automation (SDET)',
    description:
      'Master QA Automation skills by working on projects like SDETs at top product-based companies and get assured referrals',
    duration: '9 Months',
    highlights: [
      {
        title:
          'Complete Web Automation using using tools like Selenium, Webdriver, Xpath',
      },
      {
        title: 'Advance System Design, DS & Algo Curriculum',
      },
      {
        title:
          'APIs & Performance Testing using tools like Postman, Docker, Swagger',
      },
      {
        title:
          'DevOps & SDLC best practices - Using tools like CI/CD, Jenkins, Log 4j',
      },
    ],
    miscellaneousPoints: [
      {
        content: '3+ large scale professional test automation projects',
      },
      {
        content: '45+ mini-learning modules',
      },
      {
        content:
          'Learn Java, Selenium, TestNG, Cucumber, API, Perf Testing and more',
      },
    ],
    deliverables: [
      {
        title: 'Trial Session',
        description: <div>Free</div>,
      },
      {
        title: 'Duration',
        description: <div>9 months</div>,
      },
      {
        title: 'Scholarships',
        description: <div className="text-center">Assured Scholarships</div>,
      },
      {
        title: 'Career Services',
        description: <div className="text-center">Assured Referrals</div>,
      },
    ],
  },
  [EProgramIDs.DATA_SCIENCE]: {
    title: 'Fellowship Program in NextGen Data Analytics with AI',
    description:
      'Master QA Automation skills by working on projects like SDETs at top product-based companies and get assured referrals',
    duration: '9 Months',
    highlights: [
      {
        title:
          'Complete Web Automation using using tools like Selenium, Webdriver, Xpath',
      },
      {
        title: 'Advance System Design, DS & Algo Curriculum',
      },
      {
        title:
          'APIs & Performance Testing using tools like Postman, Docker, Swagger',
      },
      {
        title:
          'DevOps & SDLC best practices - Using tools like CI/CD, Jenkins, Log 4j',
      },
    ],
    miscellaneousPoints: [
      {
        content: '3+ large scale professional test automation projects',
      },
      {
        content: '45+ mini-learning modules',
      },
      {
        content:
          'Learn Java, Selenium, TestNG, Cucumber, API, Perf Testing and more',
      },
    ],
    deliverables: [
      {
        title: 'Trial Session',
        description: <div>Free</div>,
      },
      {
        title: 'Duration',
        description: <div>9 months</div>,
      },
      {
        title: 'Scholarships',
        description: <div className="text-center">Assured Scholarships</div>,
      },
      {
        title: 'Career Services',
        description: <div className="text-center">Assured Referrals</div>,
      },
    ],
  },
  [EProgramIDs.SYSTEM_DESIGN]: {
    title: 'Fellowship Program In System Design',
    description:
      'Master advanced software development and design skills with hands-on experience in System Design - Assured!',
    duration: '9 Months',
    highlights: [
      {
        title:
          'Complete Web Automation using using tools like Selenium, Webdriver, Xpath',
      },
      {
        title: 'Advance System Design, DS & Algo Curriculum',
      },
      {
        title:
          'APIs & Performance Testing using tools like Postman, Docker, Swagger',
      },
      {
        title:
          'DevOps & SDLC best practices - Using tools like CI/CD, Jenkins, Log 4j',
      },
    ],
    miscellaneousPoints: [
      {
        content: '3+ large scale professional test automation projects',
      },
      {
        content: '45+ mini-learning modules',
      },
      {
        content:
          'Learn Java, Selenium, TestNG, Cucumber, API, Perf Testing and more',
      },
    ],
    deliverables: [
      {
        title: 'Trial Session',
        description: <div>Free</div>,
      },
      {
        title: 'Duration',
        description: <div>9 months</div>,
      },
      {
        title: 'Scholarships',
        description: <div className="text-center">Assured Scholarships</div>,
      },
      {
        title: 'Career Services',
        description: <div className="text-center">Assured Referrals</div>,
      },
    ],
  },
};

const EButtonType = {
  DOWNLOAD_CURRICULUM_BUTTON: 'DOWNLOAD_CURRICULUM_BUTTON',
  DOWNLOAD_CAREER_SERVICES: 'DOWNLOAD_CAREER_SERVICES',
  DOWNLOAD_PLACEMENT_STATS: 'DOWNLOAD_PLACEMENT_STATS',
};
module.exports = {
  EPrograms,
  EProgramIDs,
  EButtonType,
};
