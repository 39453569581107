import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';

const useMarketingPopupEvents = () => {
  const popupCloseHandler = (variant) => {
    if (variant) {
      GTM.track(gtmEvents.MARKETING_POPUP, {
        type: 'popupClose',
        variant: `MARKETING_POPUP_${variant}`,
      });
    }
  };

  const popupViewHandler = (variant, condition) => {
    if (variant) {
      GTM.track(gtmEvents.MARKETING_POPUP, {
        type: 'popupView',
        variant: `MARKETING_POPUP_${variant}`,
        condition: condition || 'DEFAULT',
      });
    }
  };

  const popupCTAClickEventHandler = (variant) => {
    if (variant) {
      GTM.track(gtmEvents.MARKETING_POPUP, {
        type: 'ctaClicked',
        variant: `MARKETING_POPUP_${variant}`,
      });
    }
  };

  return {
    popupCloseHandler,
    popupViewHandler,
    popupCTAClickEventHandler,
  };
};

export default useMarketingPopupEvents;
