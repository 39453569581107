import axios from 'axios';
import {
  LEAD_GEN_DEV_API_BASE_URL,
  LEAD_GEN_PROD_API_BASE_URL,
} from '../src/constants/LeadGenerationConstants/index';

const createLeadURL =
  process.env.GATSBY_ENV === 'development'
    ? `${LEAD_GEN_DEV_API_BASE_URL}/createLead`
    : `${LEAD_GEN_PROD_API_BASE_URL}/createLead`;

const updateLeadURL =
  process.env.GATSBY_ENV === 'development'
    ? `${LEAD_GEN_DEV_API_BASE_URL}/updateLead`
    : `${LEAD_GEN_PROD_API_BASE_URL}/updateLead`;

const queueLeadAndActivityURL =
  process.env.GATSBY_ENV === 'development'
    ? `${LEAD_GEN_DEV_API_BASE_URL}/queueLeadAndActivity`
    : `${LEAD_GEN_PROD_API_BASE_URL}/queueLeadAndActivity`;
class LeadGenerationService {
  generateLead = async (lead) =>
    await axios({
      method: 'post',
      url: createLeadURL,
      data: lead,
    });

  updateLead = async (updatedLead) =>
    await axios({
      method: 'put',
      url: updateLeadURL,
      data: updatedLead,
    });
  queueLeadAndActivity = async (payload) =>
    await axios({
      method: 'post',
      url: queueLeadAndActivityURL,
      data: payload,
    });
}

export default new LeadGenerationService();
