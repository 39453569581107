import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import { LEAD_GEN_DOWNLOAD_PLACEMENT_STATS } from '@src/constants/LeadGenerationConstants/index';
import { EButtonType } from '@src/constants/data/programs/index';
import React, { useContext } from 'react';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import useCrioDownload from '@src/hooks/useCrioDownload';

const PopupDownloadButtons = ({ clickEvent }) => {
  const state = useContext(GlobalStateContext);

  const {
    triggerCareerHandbookWebengageFlow,
    triggerPlacementStatsWebengageFlow,
  } = useCrioDownload();

  return (
    <div className="flex flex-col gap-3 md:flex-row">
      <LeadGenerationButton
        type="Download"
        text="Download Career Handbook"
        isOnePageForm
        formHeading="Career Handbook"
        pageOneButtonText="Download Now"
        buttonLocation="Download_Career_Handbook"
        onSubmitPageOne={triggerCareerHandbookWebengageFlow}
        clickEvent={() => {
          clickEvent();
        }}
        {...(state.email &&
          state.phone && {
            onClick: () => {
              triggerCareerHandbookWebengageFlow(
                state.email,
                state.phone,
                EButtonType.DOWNLOAD_CAREER_SERVICES,
                'POPUP_CAREER_SERVICES',
              );
            },
          })}
      ></LeadGenerationButton>
      <LeadGenerationButton
        type="Download"
        text="Download Placement Stats"
        isOnePageForm
        formHeading="Placement Stats"
        buttonLocation={LEAD_GEN_DOWNLOAD_PLACEMENT_STATS}
        clickEvent={() => {
          clickEvent();
        }}
        pageOneButtonText="Download Now"
        onSubmitPageOne={triggerPlacementStatsWebengageFlow}
        {...(state.email &&
          state.phone && {
            onClick: () => {
              triggerPlacementStatsWebengageFlow(
                state.email,
                state.phone,
                EButtonType.DOWNLOAD_PLACEMENT_STATS,
                'POPUP_DOWNLOAD_PLACEMENT_STAT_CARD',
              );
            },
          })}
      ></LeadGenerationButton>
    </div>
  );
};

export default PopupDownloadButtons;
