import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import React, { useEffect } from 'react';

function ErrorFallback({ error, resetErrorBoundary }) {
  useEffect(() => {
    GTM.track(gtmEvents.ERROR_FALLBACK_TRIGGER, {
      errorDetails: error?.message,
    });
  }, []);
  return (
    <div className="flex h-screen w-full items-center justify-center bg-v5-neutral-200">
      <div className="container text-center">
        <h5 className="font-manrope font-light">
          Something went wrong! <br></br>Refresh page and try again.
          <br></br>
          <br></br>
          <p className="text-sm">
            If the problem persists, write to us at{' '}
            <a
              className="text-blue-800 underline"
              href={`mailto:help@criodo.com?subject=Website crash&body=Crio website v${window?.appVersion} is crashing when I visit this URL ${window.location.href}. Please look into it.`}
              target="_blank"
              rel="noreferrer"
            >
              help@criodo.com
            </a>
          </p>
        </h5>
      </div>
    </div>
  );
}
export default ErrorFallback;
