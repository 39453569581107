import React from 'react';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import useResizer from '@components/extra/useResizer';
import CrioButton from '@components/v5/CrioButton/index';
import { cn } from '@/lib/utils';
import background from '../../images/popup/PopupBackground2.webp';
import backgroundMobile from '../../images/popup/PopupBackground1Mobile.webp';
import { useLocation } from '@reach/router';
import useMarketingPopupEvents from '@src/hooks/useMarketingPopupEvents';
import RawHTML from '@components/Common/RawHTML/index';

const PopUpVariantThree = ({
  title,
  description,
  image,
  buttonText,
  onButtonClick = () => {},
  variant,
  utmParams,
}) => {
  const isMobile = useResizer();
  const location = useLocation();

  const { popupCTAClickEventHandler } = useMarketingPopupEvents();

  return (
    <div
      className="flex h-full flex-col items-center justify-center rounded-[12px] bg-[#2DF8C5] pt-10 md:flex-row md:rounded-[20px]"
      style={{
        backgroundImage: `url(${isMobile ? backgroundMobile : background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="block items-center justify-center md:hidden">{image}</div>
      <div className="flex flex-col items-center justify-center gap-4 p-3 pb-10 text-center md:w-1/2 md:items-start md:gap-6 md:py-10">
        <h2 className="text-center text-[20px] md:text-[42px]">
          <RawHTML>{title}</RawHTML>
        </h2>
        <p className="text-center text-[14px] text-black md:text-[24px]">
          <RawHTML>{description}</RawHTML>
        </p>
        <div className="flex w-full items-center justify-center">
          <LeadGenerationButton
            text="Book a Free Trial"
            type="HiddenButton"
            customUtmParams={utmParams}
            className="h-[40px] self-center rounded-[6px] md:h-[66px] md:rounded-[10px] md:px-[60px]"
            buttonLocation={`MARKETING_POPUP_${variant}`}
            clickEvent={() => {
              popupCTAClickEventHandler(variant);
              onButtonClick();
            }}
            isOnePageForm
            onSubmitPageOne={() => {
              if (location.pathname === '/qa-automation-testing/') {
                window.open(
                  'https://www.crio.do/magic/trial-workshop-qa',
                  '_blank',
                );
              } else {
                window.open(
                  'https://www.crio.do/magic/crio-trial-workshop',
                  '_blank',
                );
              }
            }}
            pageOneButtonText="Join Now"
          >
            <CrioButton variant="POPUP" className={cn(`w-fit px-[50px]`)}>
              {buttonText}
            </CrioButton>
          </LeadGenerationButton>
        </div>
      </div>
    </div>
  );
};

export default PopUpVariantThree;
