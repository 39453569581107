const EActions = {
  SET_POPUP_DATA: 'SET_POPUP_DATA',
  SET_POPUP_SHOWN_TIMESTAMP: 'SET_POPUP_SHOWN_TIMESTAMP',
};

const EPopupType = {
  DOWNLOAD_CURRICULUM: 'DOWNLOAD_CURRICULUM',
};

const EPopupParameters = {
  // 2 hours
  POPUP_VISIBILITY_DURATION: 2 * 60 * 60 * 1000,

  // 5 mins
  TRIAL_SESSION_POPUP_VISIBILITY_DURATION: 5 * 60 * 1000,

  // 30 seconds
  POPUP_TIMER_DURATION: 30 * 1000,

  POPUP_VISIBILITY_SCROLL_PERCENTAGE_MOBILE: 8,
  POPUP_VISIBILITY_SCROLL_PERCENTAGE_DESKTOP: 20,
};

module.exports = {
  EActions,
  EPopupType,
  EPopupParameters,
};
