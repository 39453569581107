const axios = require('axios');
const currentConfig = require('../Config').default;
const { GTM } = require('../analytics/gtm');
const endpointURL = currentConfig().backendUrl;

const doLoginToBackend = function (accessToken) {
  return axios
    .post(
      endpointURL + 'api/v1/auth/login',
      JSON.stringify({ firebaseJwtToken: accessToken }),

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(async (res) => {
      if (res.data.status === 'success') {
        // const userDetails = await fetchUserDetails(accessToken);
        // GTM.ga4Login(userDetails.userId);
        return true;
      } else {
        return false;
      }
    })
    .catch((e) => {
      console.log(e);
      return false;
    });
};

const fetchUserDetails = function (accessToken) {
  return axios
    .get(endpointURL + 'api/v1/user/details', {
      headers: {
        'Content-Type': 'application/json',
        'x-firebase-auth': accessToken,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return e;
    });
};

const fetchJoinSessionLink = function (slug, search) {
  return axios.get(
    endpointURL + `api/v1/magic-link/resolve-trial${slug}${search}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

module.exports = { doLoginToBackend, fetchUserDetails, fetchJoinSessionLink };
