import { useCustomToast } from '@components/extra/useCustomToast';
import { GTM } from '@src/analytics/gtm';
import { gtmEvents } from '@src/analytics/gtmEventsConstant';
import React from 'react';
import { useLocation } from '@reach/router';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import { EButtonType } from '@src/constants/data/programs/index';

const useCrioDownload = () => {
  const location = useLocation();
  const isQAPage = location.pathname.includes('/qa-automation-testing');

  const notifyHandbookToast = useCustomToast(
    'success',
    'Success! The career handbook will be emailed to you shortly.',
  );

  const placementDownloadToast = useCustomToast(
    'success',
    <div className="">
      <h2 className="font-base font-rubik text-lg">Success!</h2>
      <p className="font-rubik text-sm">
        You will receive the stats in your email shortly.
        <br /> (In case you have your adblocker turned on, please write to us at{' '}
        <a href="mailto:programs@criodo.com" className="font-bold">
          programs@criodo.com
        </a>{' '}
        to receive the stats)
      </p>
    </div>,
  );

  const triggerGTMEvents = (
    email,
    phone,
    type,
    location,
    successEvent,
    clickedEvent,
    submittedEvent,
    toast,
  ) => {
    GTM.login({ userId: email, email, phone, whatsappNumber: phone });
    GTM.setEmail(email);
    GTM.track(gtmEvents.EMAIL_SUBMITTED, { location });
    GTM.track(successEvent, { location });

    const eventCode =
      type === EButtonType.DOWNLOAD_CAREER_SERVICES
        ? clickedEvent
        : submittedEvent;

    GTM.leadSquaredEvent(eventCode, email);

    toast();
  };

  const triggerCareerHandbookWebengageFlow = (email, phone, type, location) => {
    triggerGTMEvents(
      email,
      phone,
      type,
      location,
      gtmEvents.CAREER_HANDBOOK_APPLIED_SUCCESSFULLY,
      isQAPage
        ? ELeadSquaredActivityCode.QA_PAGE_DOWNLOAD_CAREER_HANDBOOK_CLICKED
        : ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CAREER_HANDBOOK_CLICKED,
      isQAPage
        ? ELeadSquaredActivityCode.QA_PAGE_DOWNLOAD_CAREER_HANDBOOK_SUBMITTED
        : ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_CAREER_HANDBOOK_SUBMITTED,
      notifyHandbookToast,
    );
  };

  const triggerPlacementStatsWebengageFlow = (email, phone, type, location) => {
    triggerGTMEvents(
      email,
      phone,
      type,
      location,
      gtmEvents.DOWNLOAD_PLACEMENT_STAT_APPLIED_SUCCESSFULLY,
      isQAPage
        ? ELeadSquaredActivityCode.QA_PAGE_DOWNLOAD_PLACEMENT_STATS_CLICKED
        : ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_PLACEMENT_STATS_CLICKED,
      isQAPage
        ? ELeadSquaredActivityCode.QA_PAGE_DOWNLOAD_PLACEMENT_STATS_SUBMITTED
        : ELeadSquaredActivityCode.SD_PAGE_DOWNLOAD_PLACEMENT_STATS_SUBMITTED,
      placementDownloadToast,
    );
  };

  return {
    triggerPlacementStatsWebengageFlow,
    triggerCareerHandbookWebengageFlow,
  };
};

export default useCrioDownload;
