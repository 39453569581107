import React from 'react';

export default function RawHTML({ children, className = '' }) {
  return React.isValidElement(children) ? (
    children
  ) : (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: children?.replace(/\n/g, '<br />') }}
    />
  );
}
