import React from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormIcon from './FormIcon';

export default function FormDropDown({
  name,
  menu_items,
  icon,
  label,
  isOpenForm,
  handleFocus,
  isRequired,
}) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        return (
          <TextField
            value={value}
            required={isRequired}
            select
            variant="filled"
            defaultValue=""
            onFocus={handleFocus}
            name={name}
            label={label}
            helperText={error}
            onBlur={onBlur}
            ref={ref}
            onChange={onChange}
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: {
                color: isOpenForm ? '#000' : '#F2FBF7',
                borderRadius: '1rem',
                padding: 3,
                textAlign: 'left',
                background: isOpenForm ? '#fff' : '',
              },
              sx: {
                '& .MuiSelect-select:focus': {
                  'background-color': 'transparent',
                },
              },
            }}
            InputLabelProps={{
              style: {
                ...(error
                  ? { color: '#F3736A' }
                  : isOpenForm
                  ? { color: '#7A8B87' }
                  : { color: '#009378' }),
              },
            }}
            FormHelperTextProps={{
              style: {
                color: isOpenForm ? '#fff' : '#F3736A',
              },
            }}
            className={`${
              error
                ? isOpenForm
                  ? 'bg-v5-red-200'
                  : 'bg-v5-red-500'
                : isOpenForm
                ? 'bg-v5-neutral-100'
                : 'bg-v5-green-500 text-v5-neutral-200'
            } rounded-2xl`}
            inputProps={{
              'aria-label': `Enter ${label}`,
            }}
            SelectProps={{
              IconComponent: () => (
                <FormIcon
                  icon={icon}
                  error={error}
                  validated={
                    formContext.formState.dirtyFields[name] &&
                    !formContext.formState.errors[name]
                  }
                  className=""
                  isOpenForm={isOpenForm}
                />
              ),
              MenuProps: {
                PaperProps: {
                  style: {
                    borderBottomRightRadius: 16,
                    borderBottomLeftRadius: 16,
                    maxHeight: '140px',
                    borderBottom: '12px',
                    marginTop: 1,
                    zIndex: 1,
                  },
                  sx: {
                    bgcolor: isOpenForm ? '#F2FBF7' : '#003B3F',
                    color: isOpenForm ? '#525252' : '#B9CFCA',
                    '&::-webkit-scrollbar': {
                      width: 24,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: isOpenForm ? '#D3E4E0' : '#013437',
                      borderRadius: '12px',
                      border: isOpenForm ? '0px' : `4px solid #003B3F`,
                      backgroundClip: 'content-box',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: isOpenForm ? '#AEC5C0' : '#009378',
                      borderRadius: 1000,
                      border: isOpenForm ? '0px' : '4px solid #003B3F',
                      height: 24,
                      width: 24,
                    },
                    '& .Mui-selected': {
                      color: isOpenForm ? '#013437' : '',
                      'background-color': isOpenForm
                        ? '#02BF91 !important'
                        : '',
                    },
                  },
                },
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              },
            }}
          >
            {menu_items.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        );
      }}
    ></Controller>
  );
}
