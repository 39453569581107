const db = require('../src/data/db.json');

let countries = [];
db.strapi_metadata.programs.map((ele) => {
  let country = ele.countryName.split('__')[1];
  if (ele.PrimaryProgramID === 'FULL_STACK') {
    if (country != 'IN') countries.push(country);
  }
});

// ['MY', 'VN', 'TH', 'SG', 'ID'];

export default countries;
