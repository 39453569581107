import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext, Controller } from 'react-hook-form';
import FormIcon from './FormIcon';

export default function FormInput({
  name,
  label,
  icon,
  isOpenForm,
  handleFocus,
}) {
  const formContext = useFormContext();
  const error = formContext.formState.errors[name]?.message;

  return (
    <div className="relative inline-block">
      <Controller
        name={name}
        control={formContext.control}
        render={({ field }) => (
          <TextField
            variant="filled"
            label={label}
            {...field}
            helperText={error}
            fullWidth
            FormHelperTextProps={{
              style: {
                color: isOpenForm ? '#fff' : '#F3736A',
              },
            }}
            onFocus={handleFocus}
            InputProps={{
              disableUnderline: true,
              style: {
                color: isOpenForm ? '#000' : '#F2FBF7',
                padding: 3,
                borderRadius: '16px',
                background: isOpenForm ? '#fff' : '',
                border: isOpenForm ? (error ? '1px solid #E84D42' : '') : '',
              },
            }}
            sx={{
              '& input:-webkit-autofill': {
                '-webkit-box-shadow': '0 0 0 100px white inset', // Adjust autofill background
                'box-shadow': isOpenForm
                  ? '0 0 0 100px #fff inset'
                  : '0 0 0 100px #004246 inset', // Adjust autofill background
                '-webkit-text-fill-color': isOpenForm ? '#000' : '#fff', // Autofill text color
                'border-radius': 'inherit',
              },
            }}
            InputLabelProps={{
              style: {
                ...(error
                  ? { color: '#F3736A' }
                  : isOpenForm
                  ? { color: '#7A8B87' }
                  : { color: '#009378' }),
              },
            }}
            className={`${
              error
                ? isOpenForm
                  ? 'bg-v5-red-200'
                  : 'bg-v5-red-500'
                : isOpenForm
                ? 'bg-v5-neutral-100 text-v5-neutral-300 placeholder:text-v5-neutral-400'
                : 'bg-v5-green-500 text-v5-neutral-200 placeholder:text-v5-green-300'
            } rounded-2xl`}
            inputProps={{ 'aria-label': `Enter ${label}` }}
          ></TextField>
        )}
      ></Controller>
      <FormIcon
        icon={icon}
        error={error}
        validated={
          formContext.formState.dirtyFields[name] &&
          !formContext.formState.errors[name]
        }
        isOpenForm={isOpenForm}
      />
    </div>
  );
}
