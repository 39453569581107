import { getUniqueId } from './../utils/helper';
import { gtmEvents } from './gtmEventsConstant';

// const isProd = process.env.NODE_ENV === "production";
const isProd = true;

const ucwords = (str) => {
  str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
  return str;
};

function isExperimentRunning() {
  return typeof window !== 'undefined' &&
    typeof window.google_optimize !== 'undefined' &&
    typeof window.google_optimize.get(mainAbExperimentId) !== 'undefined'
    ? true
    : false;
}
// const mainAbExperimentId = 'hRKsLUKeSpeFG93e8ymJaw';
// const mainAbExperimentId = 'Quef-4ZPTLqdKo_B9BK6jw';
const mainAbExperimentId = 'pEqJZETUSIibFHV-5Kn1-A';

{
  /* Sample Component
  <Experiment id={getExperimentAndVariant().mainAbExperimentId}>
  <Variant default id="0"> Apply Now
  </Variant>
  <Variant id="1">Book Now</Variant>
  <Variant id="2">Apply Now</Variant>
</Experiment> 
*/
}
export const GTM = {
  track: (event = '', customEventData) => {
    // console.log(event, customEventData);
    let experiment =
      window.google_optimize && window.google_optimize.get(mainAbExperimentId);
    if (experiment) {
      experiment = mainAbExperimentId + ':' + experiment;
    }
    let country = window.country;
    let mobile = window.innerWidth < 768 ? true : false;
    let user_uid = getUniqueId();

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      customEventData: {
        ...customEventData,
        experiment,
        country,
        mobile,
        user_uid,
      },
      event,
    });
  },
  /**
   * @argument userData: {
   * userId: "userIdentifier - email",
   * name: "Full Name",
   * email: "email@example.com"
   * phone: "+919999999999",
   * whatsappNumber: "+919999999999",
   * profession: "Student",
   * professionalDetails: {prop: value}
   * extraAttributes: {prop: value}
   * }
   */
  leadSquaredEvent: (activityCode, email, url) => {
    if (email) {
      GTM.track(gtmEvents.LEADSQUARED_EVENT, {
        activityCode,
        url,
      });
    }
  },
  ga4Login: (userId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: gtmEvents.GA4_LOGIN,
      ga4UserId: userId,
    });
  },

  addUserAttributes: (customUserData) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: gtmEvents.ADD_USER_ATTRIBUTES,
      customUserData: customUserData,
    });
  },
  login: ({
    userId,
    name,
    email,
    phone,
    whatsappNumber,
    profession,
    professionalDetails,
    extraAttributes,
  }) => {
    if (isProd) {
      let experiment =
        window.google_optimize &&
        window.google_optimize.get(mainAbExperimentId);
      if (experiment) {
        experiment = mainAbExperimentId + ':' + experiment;
      }
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: gtmEvents.LOGIN,
        userId: userId && userId.toLowerCase(),
        customUserData: {
          we_first_name: name && ucwords(name.split(' ')[0]),
          we_last_name: name && ucwords(name.split(' ').slice(1).join(' ')),
          we_email: email && email.toLowerCase(),
          we_phone: phone,
          whatsappNumber,
          profession,
          experiment,
          ...professionalDetails,
          ...extraAttributes,
        },
      });
      window?.splitbee?.user?.set({
        displayName:
          name &&
          ucwords(name.split(' ')[0]) +
            ucwords(name.split(' ').slice(1).join(' ')),
      });
      window?.splitbee?.user?.set({ email: email && email.toLowerCase() });
    }
  },
  logout: () => {
    if (isProd) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: gtmEvents.LOGOUT,
        userId: undefined,
        customUserData: undefined,
      });
    }
  },
  emailLead: (email) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'enhanced_registration',
      enhanced_conversion_data: {
        email,
      },
    });
  },
  emailSubmission: (email, phone) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'emailSubmitted',
      enhanced_conversion_data: {
        email: email,
        phone_number: phone,
      },
    });
  },
  setEmail: (email) => {
    window.dataLayer.push({
      [gtmEvents.WEB_VAR_EMAIL]: email,
    });
  },
};
export const getExperimentAndVariant = () => {
  let variant =
    typeof window !== 'undefined' &&
    typeof window.google_optimize !== 'undefined'
      ? window.google_optimize.get(mainAbExperimentId)
      : 0;
  if (variant == null) {
    variant = 0;
  }
  return { mainAbExperimentId, variant, isExperimentRunning };
};
