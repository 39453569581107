import React from 'react';
import popAudio from '../../../src/images/pop.mp3';
import { StaticImage } from 'gatsby-plugin-image';
import { Icon } from './Icon';

export default class ChatBotIcon extends React.Component {
  timer;
  state = { activateAttention: false, soundPlayed: false };
  audioRef = React.createRef();
  componentDidMount() {
    this.timer = setTimeout(() => {
      this.setState({ activateAttention: true });
    }, 10 * 1000);
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.activateAttention != prevState.activateAttention &&
      this.state.activateAttention &&
      !this.props.isBotFirstInteration &&
      !this.state.soundPlayed
    ) {
      try {
        if (process.env.NODE_ENV === 'production') this.audioRef.current.play();
      } catch (e) {}
      this.setState({ soundPlayed: true });

      // console.log("sound played");
    }
  }

  render() {
    return (
      <>
        <div className="relative">
          {this.state.activateAttention && !this.props.isBotFirstInteration && (
            <div className="absolute -top-[68px] -left-[80px] w-[150px]">
              <StaticImage
                width={500}
                loading="eager"
                src="../../images/letschat2.webp"
                placeholder="eager"
                alt="Chatbot Reminder"
              />
            </div>
          )}
          <Icon />

          <audio controls ref={this.audioRef} style={{ display: 'none' }}>
            <source src={popAudio} type="audio/mpeg" />
          </audio>
        </div>
      </>
    );
  }
}
