const ELeadSquaredActivityCode = {
  BOOK_FREE_TRIAL_CLICKED: 310,
  APPLY_AND_START_FOR_FREE_CLICKED: 311,
  APPLY_NOW_CLICKED: 312,
  BOOK_FREE_TRIAL_SUBMITTED: 313,
  NEXT_CLICKED: 314,
  SEND_ME_FREE_PDF_SUBMITTED: 315,
  SIGN_IN_CLICKED: 316,
  CHAT_ICON: 317,
  REQUEST_CALL_BACK_CLICKED: 318,
  CALL_ME_BACK_SUBMITTED: 319,
  CHAT_WITH_ME_CLICKED: 320,
  QA_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED: 321,
  QA_PAGE_DOWNLOAD_CURRICULUM_CLICKED: 322,
  QA_PAGE_DOWNLOAD_CURRICULUM_SUBMITTED: 323,
  QA_PAGE_DOWNLOAD_PLACEMENT_STATS_CLICKED: 324,
  QA_PAGE_DOWNLOAD_PLACEMENT_STATS_SUBMITTED: 325,
  QA_PAGE_DOWNLOAD_CAREER_HANDBOOK_CLICKED: 461,
  QA_PAGE_DOWNLOAD_CAREER_HANDBOOK_SUBMITTED: 462,
  SD_PAGE_GET_OUR_PLACEMENT_STATS_CLICKED: 326,
  SD_PAGE_DOWNLOAD_CURRICULUM_CLICKED: 327,
  SD_PAGE_DOWNLOAD_CURRICULUM_SUBMITTED: 328,
  SD_PAGE_DOWNLOAD_CAREER_HANDBOOK_CLICKED: 329,
  SD_PAGE_DOWNLOAD_CAREER_HANDBOOK_SUBMITTED: 330,
  SD_PAGE_DOWNLOAD_PLACEMENT_STATS_CLICKED: 331,
  SD_PAGE_DOWNLOAD_PLACEMENT_STATS_SUBMITTED: 332,
  SUBMIT_PROJECT_SUBMITTTED: 333,
  TIME_SPENT_ON_WEBSITE_MORE_THAN_TWO_MINUTES: 334,
  TIME_SPENT_ON_WEBSITE_MORE_THAN_FIVE_MINUTES: 335,
  WEBSITE_VISITED: 336,
  VIEW_PROJECT_CLICKED: 337,
  PROJECT_DOWNLOADED: 338,
  ROI_CALCULATE_CTA_CLICKED: 448,
  ROI_SEE_INDUSTRY_TRENDS_CTA_CLICKED: 449,
  //HIRE FROM CRIO ACTIVITY CREATE
  FILLED_CALL_BACK_REQUEST_ON_HIRE_FROM_CRIO_PAGE: 203,

  //OTP Verification Activity:
  OTP_VERIFICATION: 460,

  //CERTIFICATE GENERATION ACTIVITY CODE:
  CERTIFICATE_GENERATION: 463,

  //Lead Form Trail Session,
  OTHER_DEMO_TRIAL_SLOT_SELECTION: 467,
};

module.exports = {
  ELeadSquaredActivityCode,
};
