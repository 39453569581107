import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import countries from './country-options';
import * as styles from './SingleScreenPopupWithMobile.module.scss';
// import PhoneInput from 'react-phone-input-2';
import { ESale } from '@src/constants/sale/index';
import Loader from '@components/Loader/index';
import loadable from '@loadable/component';
import 'react-phone-input-2/lib/style.css';

const PhoneInput = loadable(() => import('react-phone-input-2'), {
  fallback: <Loader></Loader>,
});

function SingleScreenPopupWithMobile(props) {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const [phone, setPhone] = useState('');

  const sale = props.sale;

  const defaultCountryCode = String(
    countries.find((ele) => ele.props['data-countrycode'] == state.country)
      .props['data-countrycode'] || 'in',
  ).toLowerCase();

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({
    mode: 'all',
  });

  function onSubmit(data) {
    GTM.login({
      userId: data.email,
      email: data.email,
      phone: '+' + String(phone),
    });
    // window.webengage && webengage.user.setAttribute('we_whatsapp_opt_in', true); //WhatsApp

    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${
          data.email
        }&country=${state.country}&type=EMAIL_CAPTURE_POPUP&location=${
          'VARIANT_' + props.variant
        }&utm=${encodeURIComponent(state.utm)}&adblocker=${
          typeof window.webengage == 'undefined' ? true : false
        }&url=${window.location.href}`,
      );
    }

    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: 'EMAIL_CAPTURE_POPUP',
      variant: 'VARIANT_' + props.variant,
    });

    dispatch({
      type: 'SET_EMAIL',
      payload: data.email,
    });
    GTM.setEmail(data.email);
    props.handleCTASubmit();
  }

  if (sale === ESale.DIWALI_SALE) {
    return (
      <div className="bg-diwali-sale-purple-900 rounded-3xl p-8 md:py-12">
        <div
          className={`${styles.cross} bg-diwali-sale-neutral-200 text-reclaim-primary-400 -mt-6 -mr-4 flex h-6 w-6 items-center justify-center rounded-full opacity-50 md:mr-0 md:-mt-8`}
          onClick={() => {
            props.closeModal();
          }}
        >
          <FontAwesomeIcon icon={['fal', 'times']} size="1x" />{' '}
        </div>
        <div className="flex flex-col items-center text-center">
          {props.title}
          <h5 className="mb-6 font-normal">{props.description}</h5>

          <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-5">
                <div className="mb-3 text-white">
                  <input
                    className={`${styles.email} ${
                      errors.email ? 'border-2 border-red-400' : ''
                    } bg-diwali-sale-purple-500 rounded-xl md:min-w-[450px]`}
                    type="email"
                    placeholder="Enter Email Address*"
                    name="email"
                    defaultValue={state.email}
                    {...register('email', {
                      required: true,
                    })}
                  ></input>
                  {errors.email && (
                    <p className="text-left text-sm font-light text-red-400">
                      Please enter a valid email address
                    </p>
                  )}
                </div>
                <div className="mb-5">
                  <div className="flex">
                    <PhoneInput
                      inputProps={{
                        required: true,
                      }}
                      placeholder="Enter phone number"
                      containerClass="md:min-w-[450px]"
                      inputClass={`text-white bg-diwali-sale-purple-500 rounded-xl md:pl-2 md:w-[80%] md:ml-[90px]`}
                      buttonClass={`${styles.diwaliSalePhoneInput} md:w-[80px] md:pl-4 bg-diwali-sale-purple-500 mr-4 rounded-xl`}
                      dropdownClass="bg-diwali-sale-purple-500"
                      country={defaultCountryCode}
                      value={phone}
                      onChange={(phone) => {
                        setPhone(phone);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={phone.length < 8 || !formState.isValid}
                  className={`cursor-pointer rounded-lg p-3 disabled:opacity-70 ${
                    styles.customFont
                  } ${
                    formState.isValid && phone.length > 8
                      ? 'bg-crio-yellow-500 hover:bg-yellow-400'
                      : 'bg-gray-300 text-gray-600'
                  } `}
                  style={{
                    background:
                      'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                  }}
                  value={props.CTAText}
                >
                  {props.CTAText}
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-up']}
                    className="ml-2 rotate-45"
                    style={{ fontSize: '17px' }}
                  ></FontAwesomeIcon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  if (sale === ESale.CYBER_SALE) {
    return (
      <div className="bg-cyber-sale-green-900 rounded-3xl p-8 md:py-12">
        <div
          className={`${styles.cross} bg-diwali-sale-neutral-200 text-reclaim-primary-400 -mt-6 -mr-4 flex h-6 w-6 items-center justify-center rounded-full opacity-50 md:mr-0 md:-mt-8`}
          onClick={() => {
            props.closeModal();
          }}
        >
          <FontAwesomeIcon icon={['fal', 'times']} size="1x" />{' '}
        </div>
        <div className="flex flex-col items-center text-center">
          <h4
            className={`${'text-cyber-sale-lime-400 customFont mb-4 text-2xl'}`}
          >
            #RelaunchYourDevCareer this Cyber Monday!
          </h4>
          <h5 className="mb-6 font-normal">
            <div className={`text-cyber-sale-neutral-100 text-lg`}>
              Earn up to Rs. 30,000 scholarship to learn in-demand
              <br className="hidden md:block" /> Full-Stack and Backend Dev
              Skills.
            </div>
          </h5>

          <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-5">
                <div className="mb-3 text-white">
                  <input
                    className={`${styles.email} ${
                      errors.email ? 'border-2 border-red-400' : ''
                    } bg-cyber-sale-green-800 rounded-xl md:min-w-[450px]`}
                    type="email"
                    placeholder="Enter Email Address*"
                    name="email"
                    defaultValue={state.email}
                    {...register('email', {
                      required: true,
                    })}
                  ></input>
                  {errors.email && (
                    <p className="text-left text-sm font-light text-red-400">
                      Please enter a valid email address
                    </p>
                  )}
                </div>
                <div className="mb-5">
                  <div className="flex">
                    <PhoneInput
                      inputProps={{
                        required: true,
                      }}
                      placeholder="Enter phone number"
                      containerClass="md:min-w-[450px]"
                      inputClass={`text-white bg-cyber-sale-green-800 rounded-xl md:pl-2 md:w-[80%] md:ml-[90px]`}
                      buttonClass={`${styles.cyberSalePhoneInput} md:w-[80px] md:pl-4 bg-cyber-sale-green-800 mr-4 rounded-xl`}
                      dropdownClass="bg-cyber-sale-green-800"
                      country={defaultCountryCode}
                      value={phone}
                      onChange={(phone) => {
                        setPhone(phone);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <button
                  type="submit"
                  disabled={phone.length < 8 || !formState.isValid}
                  className={`cursor-pointer rounded-lg p-3 disabled:opacity-70 ${
                    styles.customFont
                  } ${
                    formState.isValid && phone.length > 8
                      ? 'bg-crio-yellow-500 hover:bg-yellow-400'
                      : 'bg-gray-300 text-gray-600'
                  } `}
                  style={{
                    background:
                      'linear-gradient(107.15deg, #FFC772 -9.95%, #FF980E 108.2%)',
                  }}
                  value={props.CTAText}
                >
                  {props.CTAText}
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-up']}
                    className="ml-2 rotate-45"
                    style={{ fontSize: '17px' }}
                  ></FontAwesomeIcon>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.modal}>
      <div
        className={styles.cross}
        onClick={() => {
          props.closeModal();
        }}
      >
        <FontAwesomeIcon
          icon={['fal', 'times']}
          style={{
            color: '#000',
          }}
          size="1x"
        />{' '}
      </div>
      <div className="flex flex-col items-center text-center">
        {props.title}
        <h5 className="mb-6 font-normal">{props.description}</h5>

        <div className="">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-5">
              <div className="mb-3 ">
                <input
                  className={`${styles.email} ${
                    errors.email ? 'border-2 border-red-400' : ''
                  }`}
                  type="email"
                  placeholder="Enter Email Address*"
                  name="email"
                  defaultValue={state.email}
                  {...register('email', {
                    required: true,
                  })}
                ></input>
                {errors.email && (
                  <p className="text-left text-sm font-light text-red-400">
                    Please enter a valid email address
                  </p>
                )}
              </div>
              <div className="mb-5">
                <div className="flex">
                  <PhoneInput
                    inputProps={{
                      required: true,
                    }}
                    country={defaultCountryCode}
                    value={phone}
                    placeholder="Enter phone number"
                    onChange={(phone) => {
                      setPhone(phone);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <input
                type="submit"
                disabled={phone.length < 8 || !formState.isValid}
                className={`cursor-pointer rounded-lg p-3  ${
                  formState.isValid && phone.length > 8
                    ? 'bg-crio-yellow-500 hover:bg-yellow-400'
                    : 'bg-gray-300 text-gray-600'
                } `}
                value={props.CTAText}
              ></input>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SingleScreenPopupWithMobile;
