import React from 'react';
import ChatBotQuery from '@components/ChatBotComponent/ChatBotQuery';
import { GTM } from '../../src/analytics/gtm';
import { gtmEvents } from '../../src/analytics/gtmEventsConstant';

const programsConfig = {
  BDT: {
    intro:
      'Our Backend Web Developer Track is carefully designed to help you learn in-demand backend engineering skills by working on real-world projects.',
    path: '/backend-developer-track',
  },
  FULL_STACK: {
    intro:
      'Our Full-Stack Web Developer Track is carefully designed to help you learn in-demand MERN-stack development skills by working on real-world projects.',
    path: '/full-stack-developer-track',
  },
};

const steps = [
  { id: '1', message: 'Hi there! How can I help you today?', trigger: '2' },
  {
    id: '2',
    message:
      'Before we move ahead could you share your phone number to get in touch with you in case we get disconnected?',
    trigger: 'phone',
  },
  {
    id: 'phone',
    user: true,
    placeholder: 'Type your phone number',
    validator: (phone) => {
      var phoneno = /^\d{10}$/;
      if (phone.match(phoneno)) {
        return true;
      }
      return 'Please enter a valid phone number';
    },
    trigger: ({ steps }) => {
      GTM.track(gtmEvents.PHONE_SUBMITTED, {
        phone: '+91' + String(steps.phone.value),
        type: 'CHATBOT_PHONE_CAPTURE',
      });
      window.webengage &&
        // eslint-disable-next-line no-undef
        webengage.user.setAttribute('we_whatsapp_opt_in', true); //WhatsApp

      fetch(
        `https://script.google.com/macros/s/AKfycbzGRcNkSR1QcLDInOIPoXyB36bfwbljSAclXnilDccDG67NgGo/exec?sheet=p&phone=${steps.phone.value}&country=${window.country}&url=${window.location.pathname}`,
      )
        .then((resp) => resp.json())
        .then((resp) => {})
        .catch();
      return '5';
    },
  },
  {
    id: '5',
    message: ({ steps }) =>
      `Our team will also share more details over email to help resolve your queries. Could you share your email ID before we start?`,
    trigger: 'email',
  },
  {
    id: 'email',
    user: true,
    placeholder: 'Type your email',
    validator: (mail) => {
      if (
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          mail,
        )
      ) {
        return true;
      }
      return 'Please enter a valid email address';
    },
    trigger: 'lsq-invocation',
  },
  {
    id: 'lsq-invocation',
    component: <ChatBotQuery />,
    waitAction: true,
    replace: true,
    asMessage: true,
    delay: 0,
    trigger: 'help-intent',
  },
  {
    id: 'help-intent',
    message: 'Great. So how can I help you?',
    trigger: 'help-option',
  },
  {
    id: 'help-option',
    options: [
      {
        value: 'about',
        label: 'I would like to know more about Crio',
        trigger: 'about',
      },
      {
        value: 'programs',
        label: 'What are the programs available on Crio?',
        trigger: 'programs',
      },
      {
        value: 'otherQuestion',
        label: 'I have some other question',
        trigger: 'other-question',
      },
    ],
  },
  {
    id: 'about',
    message:
      'Crio is a project-based learning platform for developers where you build exciting tech projects and learn key developer skills hands-on.',
    trigger: 'about-2',
  },
  /*   {
    id: "about-1",
    message:
      "Crio Reclaim 2021 sale is live! Earn a 40% scholarship to learn Full-Stack and Backend Dev skills that matter. Ends on Feb 4th 2021. Its #NeverTooLate to gift yourself a stellar career in 2021.",
    trigger: "about-2",
  }, */

  {
    id: 'about-2',
    delay: 5000,
    message: 'We have two signature project-based developer tracks,',
    trigger: 'about-3',
  },
  {
    id: 'about-3',
    delay: 2000,
    message: '1. Backend Web Developer Track',
    trigger: 'about-4',
  },
  {
    id: 'about-4',
    delay: 2000,
    message: '2. Full-Stack Web Developer Track',
    trigger: 'program-learn-more-option',
  },
  {
    id: 'program-learn-more-option',
    options: [
      {
        value: '/backend-developer-track',
        label: 'I want to learn more about the Backend Web Developer Track',
        trigger: 'redirect-learn-more-init',
      },
      {
        value: '/full-stack-developer-track',
        label: 'I want to learn more about the Full-Stack Web Developer Track',
        trigger: 'redirect-learn-more-init',
      },
      {
        value: '/software-development-fellowship-program/?leadform_open=true',
        label: 'I want to try Crio first by booking a free trial',
        trigger: 'redirect-learn-more-init-trial',
      },
    ],
  },
  {
    id: 'redirect-learn-more-init-trial',
    message:
      'Great, You can book you trial by filling a small application. We are redirecting to our free trial application form.',
    trigger: 'redirect-finish',
  },
  {
    id: 'redirect-learn-more-init',
    message: 'I will redirect you to the program details page',
    trigger: 'redirect-finish',
  },
  {
    id: 'redirect-finish',
    message: 'Can I help you with something else?',
    delay: 4000,
    trigger: ({ steps }) => {
      if (typeof window !== 'undefined') {
        window.open(steps['program-learn-more-option'].value, '_self');
      }
      // navigate(steps['program-learn-more-option'].value);
      // document.querySelector('.rsc-header a')
      return 'result-option';
    },
  },
  {
    id: 'redirect-mobile',
    message: 'Please close the chat',
    trigger: window.innerWidth < 568 ? 'redirect-mobile' : 'result-option',
  },
  {
    id: 'programs',
    message: 'We have two signature project-based developer tracks,',
    trigger: 'program-option',
  },
  {
    id: 'program-option',
    options: [
      {
        value: 'BDT',
        label: 'Backend Web Developer Track',
        trigger: 'programIntro',
      },
      {
        value: 'FULL_STACK',
        label: 'Full-Stack Web Developer Track',
        trigger: 'programIntro',
      },
    ],
  },
  {
    id: 'programIntro',
    message: ({ previousValue }) => programsConfig[previousValue].intro,
    trigger: 'program-intent-option',
  },
  {
    id: 'program-intent-option',
    options: [
      {
        value: 'details',
        label: 'Can I get more details about this track?',
        trigger: 'program-intent-detail-redirect',
      },
      {
        value: 'scholarship',
        label: 'Can I get scholarships for this track?',
        trigger: 'program-intent-scholarship-redirect-1',
      },
      {
        value: 'trial',
        label: 'Can I book a free trial?',
        trigger: 'program-intent-free-trial-redirect-1',
      },
    ],
  },
  {
    id: 'program-intent-detail-redirect',
    message: 'Sure. Let me redirect you to the program details page.',
    trigger: 'program-intent-detail-redirect-finish',
  },
  {
    id: 'program-intent-detail-redirect-finish',
    message: 'Can I help you with something else?',
    delay: 4000,
    trigger: ({ steps }) => {
      if (typeof window !== 'undefined') {
        window.open(
          programsConfig[steps['program-option'].value].path,
          '_self',
        );
      }
      // navigate(programsConfig[steps['program-option'].value].path);
      // document.querySelector('.rsc-header a')
      return 'result-option';
    },
  },
  {
    id: 'program-intent-scholarship-redirect-1',
    message:
      'Yes indeed. You can bag attractive scholarships for this track by participating and excelling in our free trial. Would you like to book you trial slot?',
    trigger: 'program-intent-scholarship-redirect-2',
  },
  {
    id: 'program-intent-scholarship-redirect-2',
    options: [
      {
        value: 'confirm',
        label: 'Yes, I would like to book a free trial slot',
        trigger: 'program-intent-scholarship-redirect-3',
      },
      {
        value: 'not-confirm',
        label: 'No, take me back',
        trigger: 'programs',
      },
    ],
  },
  {
    id: 'program-intent-scholarship-redirect-3',
    message:
      'Great, You can book you trial by filling a small application. We are redirecting to our free trial application form.',
    trigger: 'program-intent-scholarship-redirect-finish',
  },
  {
    id: 'program-intent-scholarship-redirect-finish',
    message: 'Can I help you with something else?',
    delay: 4000,
    trigger: ({ steps }) => {
      if (typeof window !== 'undefined') {
        window.open(
          `/software-development-fellowship-program/?leadform_open=true`,
          '_self',
        );
      }
      // navigate('/software-development-fellowship-program/?leadform_open=true');
      // document.querySelector('.rsc-header a')
      return 'result-option';
    },
  },
  {
    id: 'program-intent-free-trial-redirect-1',
    message: 'Of course. You can book a free trial',
    trigger: 'program-intent-free-trial-redirect-2',
  },
  {
    id: 'program-intent-free-trial-redirect-2',
    message: 'Let me redirect you to the free trial page to book your slot.',
    trigger: 'program-intent-free-trial-redirect-finish',
  },
  {
    id: 'program-intent-free-trial-redirect-finish',
    message: 'Can I help you with something else?',
    delay: 4000,
    trigger: ({ steps }) => {
      if (typeof window !== 'undefined') {
        window.open(
          `/software-development-fellowship-program/?leadform_open=true`,
          '_self',
        );
      }
      // navigate('/software-development-fellowship-program/?leadform_open=true');
      // document.querySelector('.rsc-header a')
      return 'result-option';
    },
  },
  {
    id: 'other-question',
    message: 'Sure, Please type your question below in input box.',
    trigger: 'userQuestion',
  },
  {
    id: 'userQuestion',
    user: true,
    placeholder: 'Type your question here',
    trigger: 'userPhone-ques',
  },
  {
    id: 'userPhone-ques',
    message:
      'Thank you. It was nice chatting with you. We will reach out to you with an answer in 2-3 days.',
    trigger: ({ steps }) => {
      fetch(
        `https://script.google.com/macros/s/AKfycbzGRcNkSR1QcLDInOIPoXyB36bfwbljSAclXnilDccDG67NgGo/exec?sheet=peq&email=${steps.email.value}&query=${steps.userQuestion.value}&phone=${steps.phone.value}&country=${window.country}&url=${window.location.pathname}`,
      )
        .then((resp) => resp.json())
        .then((resp) => {})
        .catch();
      return 'userQuestion-finish';
    },
  },
  {
    id: 'userQuestion-finish',
    message:
      'Thank you. Someone will get in touch with you soon. Can I help you with something else?',
    trigger: 'result-option',
  },
  {
    id: 'result-option',
    options: [
      {
        value: 'YES',
        label: 'Yes',
        trigger: 'help-intent',
      },
      { value: 'NO', label: 'No Thanks', trigger: 'result-end' },
    ],
  },
  {
    id: 'result-end',
    message: ({ steps }) => {
      return `Thank you, It was nice chatting with you.`;
    },
    end: true,
  },
];
export default steps;
