import React from 'react';

export default function PageBar({ page, totalPages }) {
  return (
    <div className="flex flex-col items-end">
      <div className="text-v5-green-300">
        {page} of {totalPages}
      </div>
      <div className="h-[7px] w-full rounded-xl bg-v5-green-700">
        <div
          className={`h-[7px] rounded-xl bg-v5-green-300 ${
            page === 1 ? 'w-[50%]' : 'w-full'
          } transition-width ease duration-300`}
        ></div>
      </div>
    </div>
  );
}
