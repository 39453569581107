module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":100},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://70eef700f3a343e18bfce8059f961e7f@o305093.ingest.sentry.io/6208514","sampleRate":0.7,"release":"external-portal-gatsby@5.11.6","ignoreErrors":["The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.","Can't find variable: webengage","NotAllowedError: play() failed because the user didn't interact with the document first.","Cannot read properties of null (reading 'Error')","NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD","Cannot read property 'removeEventListener' of undefined","SecurityError: Permission denied to access property \"dispatchEvent\" on cross-origin object","Non-Error promise rejection captured with keys: isCanceled","Failed to fetch","ResizeObserver loop limit exceeded","webengage is not defined","webengage.reload is not a function","AbortController is not defined","InvalidStateError: IDBDatabase.transaction: Can't start a transaction on a closed database","InvalidStateError: Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.","AbortError: The user aborted a request.","fullscreen error","A network error (such as timeout, interrupted connection or unreachable host) has occurred.","Network Error","Cannot read properties of undefined (reading 'autoplay')"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1075759829508976","timeout":3666},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#1fb8a3","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-131530396-8","head":false,"respectDNT":false,"pageTransitionDelay":0,"defer":true,"anonymize":false,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KWQ3NRS","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Crio.Do","short_name":"Crio.Do","start_url":"/","background_color":"#7bba8c","theme_color":"#7bba8c","display":"minimal-ui","theme_color_in_head":false,"icon":"src/images/favicon.png","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9e7df616765f0413e5015879d4cc5dc9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-sw/gatsby-browser.js'),
      options: {"plugins":[],"swPath":"src/sw.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
