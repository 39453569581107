import React from 'react';

const Times = () => {
  return (
    <svg
      width="100%"
      height="100%"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.00012207"
        y="2.82843"
        width="4"
        height="21"
        transform="rotate(-45 0.00012207 2.82843)"
        fill="black"
      />
      <rect
        x="14.8492"
        y="6.10352e-05"
        width="4"
        height="21"
        transform="rotate(45 14.8492 6.10352e-05)"
        fill="black"
      />
    </svg>
  );
};

export default Times;
