import React, { useState } from 'react';

import TrialUser from '../TrialUserPopup/index';
import useMarketingPopupEvents from '@src/hooks/useMarketingPopupEvents';
import { Dialog } from '@mui/material';
import Times from '@src/images/icons/Times';
import useDownloadCurriculumPopups from '@src/hooks/useDownloadCurriculumPopups';

const MarketingPopup = ({ children }) => {
  const [open, setOpen] = useState(false);

  const { variant, closePopup } = useDownloadCurriculumPopups({
    setOpen,
  });
  const { popupCloseHandler } = useMarketingPopupEvents();

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '100dvw',
          width: '950px',
          margin: 'auto 1rem',
          borderRadius: '21px',
          overflow: 'hidden',
          boxShadow: 'none',
        },
      }}
    >
      <div
        className="absolute top-5 right-5 z-[1] h-[12px] cursor-pointer md:top-10 md:right-10 md:h-[18px]"
        onClick={() => {
          closePopup();
          popupCloseHandler(variant);
        }}
      >
        <Times />
      </div>
      <TrialUser setOpen={setOpen} variant={variant} />
    </Dialog>
  );
};

export default MarketingPopup;
