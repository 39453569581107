import { StaticImage } from 'gatsby-plugin-image';
import { GlobalStateContext } from '@src/context/GlobalContextProvider';
import React, { useContext, useEffect, useState } from 'react';

export const Icon = () => {
  const state = useContext(GlobalStateContext);
  const [tilt, setTilt] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTilt(true);
    }, 10 * 1000);

    setTimeout(() => {
      setTilt(false);
    }, 12 * 1000);

    setTimeout(() => {
      if (!state.email) {
        setTilt(true);
      }
    }, 20 * 1000);
  }, []);

  return (
    <div
      className="overflow-hidden rounded-full"
      id={'chat-icon'}
      style={{
        WebkitMaskImage: '-webkit-radial-gradient(white, black)',
        animation: tilt ? 'tiltShaking 0.3s' : '',
      }}
    >
      <StaticImage
        width={350}
        loading="eager"
        src="../../images/ChatIcon.webp"
        alt="Chatbot"
        placeholder="eager"
        style={{ borderRadius: '50%', overflow: 'hidden' }}
      />
    </div>
  );
};
