import { useLocation } from '@reach/router';

const useDataScienceProgram = () => {
  const location = useLocation();

  const isDataSciencePage =
    location.pathname === '/ai-data-analytics/' ||
    location.pathname === '/programs/ai-data-analytics/';

  const isDataSciencePpcPage =
    location.pathname === '/programs/ai-data-analytics/';

  return { isDataSciencePage, isDataSciencePpcPage };
};

export default useDataScienceProgram;
