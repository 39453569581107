import React from 'react';
export const FormStateContext = React.createContext();
export const FormDispatchContext = React.createContext();

const SET_PROFILE = 'SET_PROFILE';
const SET_DOCUMENT_ID = 'SET_DOCUMENT_ID';
const SET_EMAIL = 'SET_EMAIL';
const SET_WORKING_STATUS = 'SET_WORKING_STATUS';

const initialFormState = {
  profile: '',
  documentid: '',
  email: '',
};

function reducer(state, action) {
  switch (action.type) {
    case SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }
    case SET_DOCUMENT_ID: {
      return {
        ...state,
        documentid: action.payload,
      };
    }
    case SET_EMAIL: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case SET_WORKING_STATUS: {
      return {
        ...state,
        workingStatus: action.payload,
      };
    }
    default:
      throw new Error('Bad Action Type');
  }
}

const FormContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialFormState);

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>
        {children}
      </FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};

export default FormContextProvider;
