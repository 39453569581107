import React from 'react';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import useMarketingPopupEvents from '@src/hooks/useMarketingPopupEvents';
import { cn } from '@/lib/utils';
import PopupDownloadButtons from './PopupDownloadButtons';
import { EPopupType } from '@src/constants/general/index';
import RawHTML from '@components/Common/RawHTML/index';

const PopUpVariantOne = ({
  title,
  description,
  image,
  mobileImage,
  onButtonClick = () => {},
  buttonText,
  variant,
  type,
  utmParams,
}) => {
  const { popupCTAClickEventHandler } = useMarketingPopupEvents();

  return (
    <div className="flex h-full flex-col md:flex-row">
      <div className="block md:hidden">{mobileImage}</div>
      <div className="hidden md:block">{image}</div>
      <div className="flex flex-col items-center gap-4 p-3 py-10 md:items-start md:gap-6 md:p-[60px] md:px-10">
        <h2
          className={cn(
            'text-center text-[20px] md:text-left md:text-[42px]',
            type === EPopupType.DOWNLOAD_CURRICULUM
              ? 'font-manrope text-[20px] font-semibold leading-[28px] md:text-[38px] md:leading-[42px]'
              : '',
          )}
        >
          <RawHTML>{title}</RawHTML>
        </h2>
        <p className="text-center text-[14px] text-[#7A8B87] md:text-left md:text-[24px]">
          <RawHTML>{description}</RawHTML>
        </p>
        <div>
          {type === EPopupType.DOWNLOAD_CURRICULUM ? (
            <PopupDownloadButtons
              clickEvent={() => {
                popupCTAClickEventHandler(variant);
                onButtonClick();
              }}
            />
          ) : (
            <LeadGenerationButton
              customUtmParams={utmParams}
              type="Apply Now"
              className="h-[40px] rounded-[6px] md:h-[66px] md:rounded-[10px] md:px-[60px]"
              buttonLocation={`MARKETING_POPUP_${variant}`}
              text={buttonText || 'Book a Free Trial'}
              clickEvent={() => {
                popupCTAClickEventHandler(variant);
                onButtonClick();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PopUpVariantOne;
