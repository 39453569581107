import { GlobalDispatchContext } from '@src/context/GlobalContextProvider';
import axios from 'axios';
import { useContext, useEffect } from 'react';

const USER_LOCATION_URL =
  'https://geolocation-db.com/json/4bdf2390-d062-11ec-81c2-0baa51ec38e1';

export const useUserLocation = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const getUserLocationDetails = async () => {
    try {
      const apiResponse = await axios({
        method: 'get',
        url: USER_LOCATION_URL,
      });

      const apiData = apiResponse.data || {};
      dispatch({
        type: 'SET_USER_LOCATION',
        payload: apiData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserLocationDetails();
  }, []);
};
