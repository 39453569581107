import devConfig from './development';
import prodConfig from './production';

const getConfig = () => {
  switch (process.env.GATSBY_ENV) {
    case 'development':
      return devConfig;
    case 'staging':
      return prodConfig;
    case 'production':
      return prodConfig;
    default:
      return devConfig;
  }
};
const currentConfig = () => getConfig();

export default currentConfig;
