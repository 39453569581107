import data from '../data/new_strapi.json';

const useMarketingPopupData = () => {
  const {
    popupTimerDuration,
    popupVisibilityDuration,
    popupVisibilityScrollPercentageDesktop,
    popupVisibilityScrollPercentageMobile,
    trialSessionPopupVisibilityDuration,
  } =
    data?.new_strapi?.strapi_marketing_popup_data?.data?.[0]?.attributes || {};

  return {
    popupTimerDuration: +popupTimerDuration,
    popupVisibilityDuration: +popupVisibilityDuration,
    popupVisibilityScrollPercentageDesktop:
      +popupVisibilityScrollPercentageDesktop,
    popupVisibilityScrollPercentageMobile:
      +popupVisibilityScrollPercentageMobile,
    trialSessionPopupVisibilityDuration: +trialSessionPopupVisibilityDuration,
  };
};

export default useMarketingPopupData;
