let config = {
  env: 'production',
  backendUrl: 'https://consumer-backend.prod.crio.do/',
  assessmentUrl: 'https://assessment-service.prod.crio.do/',
  socketUrl: 'https://socket-service.prod.crio.do',

  firebaseConfig: {
    apiKey: 'AIzaSyDWS_iHbexeEQVISuvCkPM6ng02JqR19dg',
    authDomain: 'auth.crio.do',
    databaseURL: 'https://crio-prod.firebaseio.com',
    storageBucket: 'crio-prod.appspot.com',
    projectId: 'crio-prod',
    appId: '1:812920491762:web:e2e5e0f7df3c2fec',
  },
  helpUrl: 'https://scalable-help.prod.crio.do/',
  maintenanceInProgess: false,
};

export default config;
