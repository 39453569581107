import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import { twMerge } from 'tailwind-merge';
import { EProgramIDs } from '@src/constants/data/programs/index';

export default function CrioButton({ variant, children, className, ...props }) {
  const getButton = (variant, children, className, props) => {
    switch (variant) {
      case 'primary':
        return (
          <Button
            {...props}
            disableRipple
            className={twMerge(
              `natural sm flex h-[56px] items-center  whitespace-nowrap  rounded-[10px] border-primary-200 bg-primary-200  px-4 py-2 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-primary-200/50 hover:bg-primary-100 hover:shadow-xl hover:shadow-primary-100/50 ${
                className ? className : ''
              }`,
            )}
            type="contained"
          >
            <div>{children}</div>
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-xl"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        );
      case 'secondary':
        return (
          <Button
            {...props}
            disableRipple
            className={twMerge(`natural sm h-[56px] whitespace-nowrap rounded-[10px] border-2 border-black   px-4 py-2
             font-manrope text-base font-bold  capitalize text-black transition-all hover:border-v5-green-400 hover:bg-v5-green-400 hover:text-v5-green-100 ${
               className ? className : ''
             } `)}
            variant="outlined"
          >
            {children}
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-xl"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        );

      case 'crio-reboot':
        return (
          <Button
            {...props}
            disableRipple
            className={twMerge(
              `natural sm flex h-[26px] items-center  whitespace-nowrap  rounded-[6px] border-primary-200 bg-primary-200  px-12 py-1 font-manrope text-base font-bold capitalize text-black shadow-lg  hover:bg-primary-100 ${
                className ? className : ''
              } h-[50px] py-0 px-12`,
            )}
            type="contained"
          >
            <div>{children}</div>
          </Button>
        );

      case EProgramIDs.LAUNCH:
        return (
          <Button
            {...props}
            disableRipple
            className={twMerge(
              `natural sm flex h-[56px] items-center  whitespace-nowrap  rounded-[10px]  bg-white  px-4 py-2 font-manrope text-base font-bold capitalize text-black shadow-lg hover:bg-launch-green-100 hover:shadow-xl hover:shadow-launch-green-100/50 ${
                className ? className : ''
              }`,
            )}
            type="contained"
          >
            <div>{children}</div>
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-xl"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        );

      case 'POPUP':
        return (
          <Button
            {...props}
            disableRipple
            className={twMerge(
              `natural sm flex h-[42px] items-center whitespace-nowrap  rounded-[10px]  bg-black  px-4  py-2 font-manrope text-base font-bold capitalize text-white shadow-lg md:h-[56px] ${
                className ? className : ''
              }`,
            )}
            type="contained"
          >
            <div>{children}</div>
            <FontAwesomeIcon
              icon={['fas', 'angle-right']}
              className="ml-2 mt-1 text-xl"
              style={{ marginBottom: '2px' }}
            ></FontAwesomeIcon>
          </Button>
        );

      default:
        return <></>;
    }
  };
  return getButton(variant, children, className, props);
}
