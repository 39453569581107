const ESale = {
  DIWALI_SALE: 'DIWALI_SALE',
  CYBER_SALE: 'CYBER_SALE',
  YEAR_OF_DOING_SALE: 'YEAR_OF_DOING_SALE',
};

const EScholarship = {
  FREE_TRIAL: '45,000',
  BACKEND_DEVELOPER_EXPERIENCE: '45,000',
  FULL_STACK: '45,000',
  FELLOWSHIP: '45,000',
  QA_AUTOMATION_TESTING: '45,000',
  MASTERS_PROGRAM: '45,000',
};

module.exports = {
  ESale,
  EScholarship,
};
