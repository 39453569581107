import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@mui/material';

export default function FormSubmitButton({ loading, text, className }) {
  return (
    <Button
      size="small"
      disabled={loading}
      type="submit"
      variant="contained"
      className={`transition-width ease mb-2 ml-auto min-h-[56px] whitespace-nowrap rounded-xl bg-v5-yellow-100 px-[36px] font-manrope text-sm font-bold capitalize text-black duration-300 ${className}`}
    >
      {loading ? (
        <CircularProgress className="text-black" size="0.9rem" />
      ) : (
        <div>
          {text}
          <FontAwesomeIcon
            icon={['fas', 'angle-right']}
            className="ml-2"
            style={{ fontSize: '17px' }}
          ></FontAwesomeIcon>
        </div>
      )}
    </Button>
  );
}
