import React from 'react';
import LeadGenerationButton from '@components/LeadGenerationForm/LeadGenerationButton';
import useResizer from '@components/extra/useResizer';
import CrioButton from '@components/v5/CrioButton/index';
import { cn } from '@/lib/utils';
import background from '../../images/popup/PopupBackground1.webp';
import backgroundMobile from '../../images/popup/PopupBackground1Mobile.webp';
import useMarketingPopupEvents from '@src/hooks/useMarketingPopupEvents';
import RawHTML from '@components/Common/RawHTML/index';

const PopUpVariantTwo = ({
  title,
  description,
  image,
  buttonText,
  onButtonClick = () => {},
  variant,
  utmParams,
}) => {
  const isMobile = useResizer();
  const { popupCTAClickEventHandler } = useMarketingPopupEvents();

  return (
    <div
      className="flex h-full flex-col rounded-[12px] bg-[#2DF8C5] md:flex-row md:rounded-[20px]"
      style={{
        backgroundImage: `url(${isMobile ? backgroundMobile : background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="flex items-center justify-center">{image}</div>
      <div className="flex flex-1 flex-col items-center gap-4 p-3 pb-10 md:items-start md:gap-6 md:p-[60px] md:px-10">
        <h2 className="text-center text-[20px] md:text-left md:text-[42px]">
          <RawHTML>{title}</RawHTML>
        </h2>
        <p className="text-center text-[14px] text-black md:text-left md:text-[24px]">
          <RawHTML>{description}</RawHTML>
        </p>
        <div>
          <LeadGenerationButton
            text=""
            type="HiddenButton"
            className="h-[40px] rounded-[6px] md:h-[66px] md:rounded-[10px] md:px-[60px]"
            buttonLocation={`MARKETING_POPUP_${variant}`}
            clickEvent={() => {
              popupCTAClickEventHandler(variant);
              onButtonClick();
            }}
            customUtmParams={utmParams}
          >
            <CrioButton variant="POPUP" className={cn(`w-fit px-[50px]`)}>
              {buttonText}
            </CrioButton>
          </LeadGenerationButton>
        </div>
      </div>
    </div>
  );
};

export default PopUpVariantTwo;
