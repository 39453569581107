import React from 'react';
import { FormControlLabel, Checkbox, Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import { cn } from '@/lib/utils';

export default function FormCheckbox({ text, name, isOpenForm }) {
  return (
    <FormControlLabel
      className="mr-auto ml-0 table"
      control={
        <Controller
          name={name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <div className="table-cell h-8 w-10 md:w-8">
              <Checkbox
                sx={{
                  color: isOpenForm ? '#013437' : '#004246',
                  height: 20,
                  '&.Mui-checked': {
                    color: isOpenForm ? '#02BF91' : '#2DF8C5',
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 20,
                  },
                }}
                checked={!!value}
                onChange={(e) => onChange(e.target.checked)}
                onBlur={onBlur}
                ref={ref}
                inputProps={{ 'aria-label': { name } }}
              />
            </div>
          )}
        ></Controller>
      }
      label={
        <Box
          component="div"
          fontSize={13}
          className={cn(
            `font-light  ${
              isOpenForm
                ? 'text-left font-rubik text-[8px] italic text-v5-neutral-600 md:text-[10px]'
                : 'text-v5-neutral-200'
            }`,
          )}
        >
          {text}
        </Box>
      }
    />
  );
}
