import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Controller } from 'react-hook-form';
import FormIcon from './FormIcon';
import 'react-phone-input-2/lib/material.css';
import './phone-input.css';
import { cn } from '@/lib/utils';

export default function FormPhoneInput({
  error,
  validation,
  isOpenForm,
  handleFocus,
  handleBlur,
  className,
}) {
  return (
    <div
      className={cn(`relative inline-block ${error ? 'pb-4' : ''}`, className)}
    >
      <Controller
        name="phone"
        render={({ field }) => (
          <PhoneInput
            country="in"
            placeholder="Phone"
            name="phone"
            countryCodeEditable={false}
            onFocus={handleFocus}
            onBlur={handleBlur}
            defaultErrorMessage={error}
            className={`${isOpenForm ? 'lead-form open-form' : 'lead-form'}`}
            isValid={() => {
              if (typeof window !== 'undefined' && document) {
                error
                  ? document.documentElement.style.setProperty(
                      '--phone-input-color',
                      '#F3736A',
                    )
                  : isOpenForm
                  ? document.documentElement.style.setProperty(
                      '--phone-input-color',
                      '#7A8B87',
                    )
                  : document.documentElement.style.setProperty(
                      '--phone-input-color',
                      '#009378',
                    );
              }
              return error ? false : true;
            }}
            inputProps={{
              required: true,
            }}
            inputStyle={{
              width: '100%',
              color: isOpenForm ? '#000' : '#F2FBF7',
              ...(error
                ? isOpenForm
                  ? { background: '#fff' }
                  : { background: '#4D3230' }
                : isOpenForm
                ? { background: '#fff' }
                : { background: '#004246' }),
              border: isOpenForm
                ? error
                  ? '1px solid #E84D42'
                  : 'none'
                : 'none',
              outline: 'none',
            }}
            {...field}
          />
        )}
      ></Controller>
      <FormIcon
        icon={<FontAwesomeIcon icon={faPhone} />}
        error={error}
        validated={validation}
        isOpenForm={isOpenForm}
      />
    </div>
  );
}
