let config = {
  env: 'development',
  helpUrl: 'https://scalable-help.dev.crio.do/',
  backendUrl: 'https://consumer-backend.dev.crio.do/',
  assessmentUrl: 'https://assessment-service.dev.crio.do/',
  socketUrl: 'https://socket-service.dev.crio.do',
  firebaseConfig: {
    apiKey: 'AIzaSyCxuahB0__3caabIoT543lYf_PI2r0D980',
    authDomain: 'auth.dev.crio.do',
    databaseURL: 'https://crio-do-dev.firebaseio.com',
    projectId: 'crio-do-dev',
    storageBucket: 'crio-do-dev.appspot.com',
    appId: '1:92742650112:web:03f1b203bdfd24299ccbe5',
  },
  maintenanceInProgess: false,
};

export default config;
