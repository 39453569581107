import React from 'react';
import Dialog from '@mui/material/Dialog';

const EMPTY_FUNC = () => {};
const DialogContext = React.createContext([EMPTY_FUNC, EMPTY_FUNC]);
export const useDialog = () => React.useContext(DialogContext);

function DialogContainer(props) {
  const { children, open, onClose, onKill, closeCallback } = props;

  const onCloseDialog = () => {
    // This callback is used to add some custom logic on close of dialog boxes whenever needed
    if (closeCallback && typeof closeCallback === 'function') {
      closeCallback();
    }
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      onExited={onKill}
      disableScrollLock={true}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: '100vw',
          margin: 'auto 1rem',
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: 'none',
          ...(props.transparentBackground && {
            backgroundColor: 'transparent',
          }),
        },
      }}
      PaperProps={{ style: { position: 'static' } }}
    >
      {children}
    </Dialog>
  );
}

export default function DialogProvider({ children }) {
  const [dialogs, setDialogs] = React.useState([]);
  const createDialog = (option) => {
    const dialog = { ...option, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };
  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };
  const contextValue = React.useRef([createDialog, closeDialog]);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const { onClose, ...dialogParams } = dialog;
        const handleKill = () => {
          if (dialog.onExited) dialog.onExited();
          setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
        };

        return (
          <DialogContainer
            key={i}
            onClose={closeDialog}
            onKill={handleKill}
            {...dialogParams}
          />
        );
      })}
    </DialogContext.Provider>
  );
}
