import React, { Component } from 'react';
import './loader.scss';

export default class Loader extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <svg
          className="spinner"
          width={this.props.heightVal ? this.props.heightVal : '65px'}
          height={this.props.widthVal ? this.props.widthVal : '65px'}
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="path"
            fill="none"
            strokeWidth="6"
            strokeLinecap="round"
            cx="33"
            cy="33"
            r="10"
          ></circle>
        </svg>
      </div>
    );
  }
}
