import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import PopUpVariantTwo from '../PopUpVariantTwo';
import PopUpVariantOne from '../PopUpVariantOne';
import PopUpVariantThree from '../PopUpVariantThree';
import useResizer from '@components/extra/useResizer';
import useMarketingPopupEvents from '@src/hooks/useMarketingPopupEvents';
import { EPopupType } from '@src/constants/general/index';
import { useLocation } from '@reach/router';
import useMarketingPopupVariant from '@src/hooks/useMarketingPopupVariant';

const TrialUser = ({ setOpen, variant }) => {
  const isMobile = useResizer();
  const location = useLocation();

  const { getVariantDataByVariantId } = useMarketingPopupVariant();

  const isQAPage = location.pathname.includes('/qa-automation-testing');

  const utmParams = {};

  const onButtonClick = () => {
    if (!isMobile) {
      setOpen(false);
    }
  };

  const { popupViewHandler } = useMarketingPopupEvents();

  useEffect(() => {
    popupViewHandler(variant);
  }, []);

  const getPopupByVariant = (variant) => {
    const data = getVariantDataByVariantId(variant);
    switch (variant) {
      case 1:
        return (
          <PopUpVariantOne
            title={data.title}
            description={data.description}
            image={
              <StaticImage
                loading="lazy"
                src={`../../../images/popup/CrioCelebration.webp`}
                className="h-full w-[280px] rounded-l-[20px]"
                placeholder="blurred"
                alt="About Us"
                width={280}
              />
            }
            mobileImage={
              <StaticImage
                loading="lazy"
                src={`../../../images/popup/CrioCelebrationMobile.webp`}
                className="h-full w-full rounded-t-[12px]"
                placeholder="blurred"
                alt="About Us"
                width={312}
              />
            }
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            variant={variant}
            buttonText={data.CTA}
          />
        );

      case 2:
        return (
          <PopUpVariantTwo
            title={data.title}
            description={data.description}
            image={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/PopupImage2.webp'}
                className="h-[170px] w-[170px] translate-y-6 rounded-t-[12px] md:h-[300px]  md:w-[300px] md:translate-y-0"
                placeholder="blurred"
                alt="About Us"
                width={245}
              />
            }
            buttonText={'Get Started'}
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            variant={variant}
          />
        );

      case 3:
        return (
          <PopUpVariantOne
            title={
              isQAPage
                ? 'Crack QA Automation roles in top tech companies. Get insights now.'
                : data.description
            }
            description={''}
            image={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/PaperPlane.webp'}
                className="h-full w-[280px] rounded-l-[20px]"
                placeholder="blurred"
                alt="About Us"
                width={280}
              />
            }
            mobileImage={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/PaperPlaneMobile.webp'}
                className="h-full w-full rounded-t-[12px]"
                placeholder="blurred"
                alt="About Us"
                width={312}
              />
            }
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            variant={variant}
            type={EPopupType.DOWNLOAD_CURRICULUM}
            buttonText={'Get your ticket NOW!'}
          />
        );

      case 4:
        return (
          <PopUpVariantOne
            title={data.title}
            description={data.description}
            image={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/CrioKey.webp'}
                className="h-full w-[280px] rounded-l-[20px]"
                placeholder="blurred"
                alt="About Us"
                width={280}
              />
            }
            mobileImage={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/CrioKeyMobile.webp'}
                className="h-full w-full rounded-t-[12px]"
                placeholder="blurred"
                alt="About Us"
                width={312}
              />
            }
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            buttonText={'Unlock Now'}
            variant={variant}
          />
        );

      case 5:
        return (
          <PopUpVariantTwo
            title={data.title}
            description={data.description}
            image={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/PopupImage1.webp'}
                className="h-[150px] w-[150px] rounded-t-[12px] md:h-[300px] md:w-[300px]"
                placeholder="blurred"
                alt="About Us"
                width={245}
              />
            }
            buttonText={'Grab Scholarship Now'}
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            variant={variant}
          />
        );

      case 6:
        return (
          <PopUpVariantOne
            title={data.title}
            description={data.description}
            image={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/CrioCoupon.webp'}
                className="h-full w-[280px] rounded-l-[20px]"
                placeholder="blurred"
                alt="About Us"
                width={280}
              />
            }
            mobileImage={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/CrioCouponMobile.webp'}
                className="h-full w-full rounded-t-[12px]"
                placeholder="blurred"
                alt="About Us"
                width={312}
              />
            }
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            variant={variant}
            buttonText={'Get your ticket NOW!'}
          />
        );

      case 7:
        return (
          <PopUpVariantThree
            title={data.title}
            description={data.description}
            image={
              <StaticImage
                loading="lazy"
                src={'../../../images/popup/Invite.webp'}
                className="h-full w-[140px] rounded-l-[20px]"
                placeholder="blurred"
                alt="About Us"
                width={280}
              />
            }
            buttonText={'Join Now!'}
            onButtonClick={onButtonClick}
            utmParams={utmParams}
            variant={variant}
          />
        );

      default:
        return <></>;
    }
  };

  return <>{getPopupByVariant(variant)}</>;
};

export default TrialUser;
