export const gtmEvents = {
  VIEW_ALL_PROGRAMS: 'viewAllPrograms',
  BOOK_A_FREE_TRIAL: 'bookAFreeTrial',
  TRY_CRIO_FOR_FREE: 'tryCrioForFree',
  ADD_USER_ATTRIBUTES: 'addUserAttributes',

  FREE_ME_START_NOW_CLICKED: 'freeMeStartNowClicked',
  FREE_ME_MORE_DETAILS_CLICKED: 'freeMeMoreDetailsClicked',
  FREE_ME_EMAIL_SUBMITTED: 'freeMeEmailSubmitted',
  FREE_ME_EMAIL_FORM_CLOSED: 'freeMeEmailFormClosed',

  PROGRAM_ME_APPLY_NOW_CLICKED: 'programMeApplyNowClicked',
  PROGRAM_ME_EMAIL_SUBMITTED: 'programMeEmailSubmitted',
  PROGRAM_ME_EMAIL_FORM_CLOSED: 'programMeEmailFormClosed',
  PROGRAM_ME_APPLIED_SUCCESSFULLY: 'programMeAppliedSuccessfully',
  PROGRAM_ME_MORE_DETAILS_CLICKED: 'programMeMoreDetailsClicked',
  PROGRAM_ME_REGISTER_WEBINAR_CLICKED: 'programMeRegisterWebinarClicked',
  PROGRAM_ME_CTA_CLICKED: 'programMeCTAClicked',
  PROGRAM_GET_STARTED_CLICKED: 'programGetStartedClicked',

  PROGRAM_I_AM_INTERESTED_CLICKED: 'programIAmInterestedClicked',
  PROGRAM_INTEREST_EMAIL_SUBMITTED: 'programInterestEmailSubmitted',
  PROGRAM_WEBINAR_EMAIL_SUBMITTED: 'programWebinarEmailSubmitted',
  PROGRAM_ME_REGISTER_WEBINAR_CLOSED: 'programWebinarEmailClosed',

  CRIO_PROGRAM_LEARN_MORE_CLICKED: 'crioProgramLearnMoreClicked',

  CRIO_ME_ENROLL_SUCCESSFUL: 'crioMeEnrollSuccessful',

  CHALLENGE_LEARN_MORE_CLICKED: 'challengeLearnMoreClicked',
  CHALLENGE_EMAIL_SUBMITTED: 'challengeEmailSubmitted',
  CHALLENGE_DETAIL_REGISTER_NOW_CLICKED: 'challengeDetailRegisterNowClicked',
  CHALLENGE_SUBMIT_BLOG_CLICKED: 'challengeSubmitBlogClicked',

  BVS_LEARN_MORE_CLICKED: 'BVSLearnMoreClicked',
  BVS_DETAIL_REGISTER_NOW_CLICKED: 'BVSDetailRegisterNowClicked',
  BVS_EMAIL_SUBMITTED: 'BVSEmailSubmitted',
  BVS_EMAIL_FORM_CLOSED: 'BVSEmailFormClosed',

  LEAD_MAGNET_EMAIL_SUBMITTED: 'leadMagnetEmailSubmitted',
  TYPEFORM_LOGIN_CLICKED: 'typeformLoginClicked',

  BECOME_A_PRODUCT_DEVELOPER: 'becomeAProductDeveloper',
  CONQUER_THE_CODING_INTERVIEW: 'conquerTheCodingInterview',

  FOR_BUSINESS_CLICKED: 'forBusinessClicked',
  HIRE_DEVELOPER_CLICKED: 'hireDeveloperClicked',

  ADVISOR_LEAD_CARD_TALK_TO_ADVISOR_CLICKED:
    'advisorLeadCardTalkToAdvisorClicked',
  ADVISOR_LEAD_CARD_EMAIL_SUBMITTED: 'advisorLeadCardEmailSubmitted',
  ADVISOR_LEAD_CARD_FORM_CLOSED: 'advisorLeadCardFormClosed',

  AFFILIATE_EMAIL_SUBMITTED: 'affiliateEmailSubmitted',
  AFFILIATE_COUPON_COPIED: 'affiliateCouponCopied',
  AFFILIATE_BDT_GUIDE_DOWNLOADED: 'affiliateBDTGuideDownloaded',
  AFFILIATE_INTERVIEW_GUIDE_DOWNLOADED: 'affiliateInterviewGuideDownloaded',

  PROMOTIONAL_BANNER_CLICKED: 'promotionalBannerClicked',
  MARKETING_POPUP: 'marketingPopup',

  TYPEFORM_SUBMITTED: 'typeformSubmitted',

  LOGOUT: 'logout',
  LOGIN: 'login',
  GA4_LOGIN: 'ga4_login',
  USER_TRACK: 'userTrack',

  NAVBAR_EVENT: 'navbarEvent',

  PHONE_SUBMITTED: 'phoneSubmitted',
  EMAIL_SUBMITTED: 'emailSubmitted',
  EMAIL_CAPTURE_POPUP_VISIBLE: 'emailCapturePopupVisible',

  FAB_CLICKED: 'fabClicked',
  CHATBOT_OPENED: 'chatbotOpened',
  CALLME_OPENED: 'callMeOpened',

  ME_FAQ_TAB_OPENED: 'meFaqTabOpened',
  ME_FAQ_CHECKED: 'meFaqChecked',
  ME_FAQ_DISCUSSION_LINK_CLICKED: 'meFaqDiscussionLinkClicked',
  ME_DEBRIEF_TAB_OPENED: 'meDebriefTabOpened',
  ME_ASSESSMENT_TAB_OPENED: 'meAssessmentTabOpened',
  ME_TASK_BOARD_TAB_OPENED: 'meTaskBoardTabOpened',
  ME_OVERVIEW_TAB_OPENED: 'meOverviewTabOpened',
  ME_MODULE_CHANGED: 'meModuleChanged',
  ME_TASK_MARK_AS_DONE: 'meTaskMarkAsDone',

  POST_COMPLETION_SAVE_THE_DATE_CLICKED: 'saveTheDateClicked',
  POST_COMPLETION_START_ACTIVITY_CLICKED: 'startActivityClicked',
  POST_COMPLETION_WHATSAPP_INVITATION_CLICKED:
    'postCompWhatsAppInvitationClicked',
  POST_COMPLETION_JOIN_SLACK_CLICKED: 'postCompJoinSlackClicked',
  POST_COMPLETION_LOGIN_NOW_CLICKED: 'postCompLoginNowClicked',
  POST_COMPLETION_GET_MIN_SALARY_CLICKED: 'postCompGetMinSalaryClicked',

  POST_COMPLETION_TALK_TO_COUNSELLOR: 'talkToCounsellor',
  POST_COMPLETION_VIEW_FAQS: 'viewFAQs',

  BYTE_CARD_CLICKED: 'byteCardClicked',

  PROJECT_DOWNLOAD_APPLIED_SUCCESSFULLY: 'projectDownloadAppliedSuccessfully',
  PROJECT_DOWNLOAD_CLICKED: 'projectDownloadClicked',
  PROJECT_NOTIFY_APPLIED_SUCCESSFULLY: 'projectNotifyAppliedSuccessfully',
  PROJECT_NOTIFY_CLICKED: 'projectNotifyClicked',

  DOWNLOAD_CURRICULUM_CLICKED: 'downloadCurriculumClicked',
  DOWNLOAD_CURRICULUM_APPLIED_SUCCESSFULLY:
    'downloadCurriculumAppliedSuccessfully',

  CAREER_HANDBOOK_APPLIED_SUCCESSFULLY: 'careerHandbookAppliedSuccessfully',
  DOWNLOAD_PLACEMENT_STAT_APPLIED_SUCCESSFULLY:
    'downloadPlacementStatAppliedSuccessfully',
  CAREER_HANDBOOK_CLICKED: 'careerHandbookClicked',
  PROJECT_CATEGORY_CLICKED: 'projectCategoryClicked',

  IMPRESSION_CAPTURED: 'impressionCaptured',
  APPLIED_TO_PROGRAM: 'appliedToProgram',

  LEAD_GEN_FORM_OPEN: 'leadGenFormOpen',
  LEAD_GEN_HALF_FORM_FILL: 'leadGenHalfCompletion',
  LEAD_GEN_FULL_FORM_FILL: 'leadGenFullCompletion',
  LEAD_GEN_FORM_ERRORS: 'leadGenErrors',
  CWP_EVENT: 'CWPEvent',

  USER_SIGNIN: 'userSigninClick',
  USER_SIGNEDUP: 'userSignedup',
  FIELD_FOCUSSED: 'fieldFocussed',
  POPUP_CLOSED: 'popupClosed',

  // Generalized event: append custom data to payload and use this event for future click event captures
  USER_CLICK_EVENT: 'userClickCapture',

  WEB_VAR_EMAIL: 'email',
  LEADSQUARED_EVENT: 'leadsquaredEvent',

  CTA_CLICKED: 'ctaClicked',

  //pre-revamp webengage events for fsd page:
  PAGE_VIEW_FSD: 'pageViewFSD',
  SCROLL_TO_CURRICULUM_SECTION: 'scrollToCurriculumSection',
  CURRICULLUM_DROPDOWN_CLICK: 'curriculumDropdownButtonClick',
  APPLY_BUTTON_BELOW_CURRICULLUM_CLICKED: 'applyButtonClickBelowCurriculum',

  //post-revamp webengage events for fsd page:
  LINK_CLICK: 'linkClick',
  SCROLL_TO_CURRICULUM_SECTION_V2: 'scrollToCurriculumSectionV2',
  CURRICULUM_TAB_SWITCHED: 'curriculumTabSwitched',
  SPECIALIZATION_TYPE_SWITCHED: 'specializationTypeTabSwitch',

  //Error page track:
  ERROR_FALLBACK_TRIGGER: 'errorFallbackTrigger',

  //GA EVENTS:
  PAGE_VIEW: 'pageView',
  LEAD_SOURCE_CTA_CLICK: 'leadSourceCTAClick',
  L1_FORM_FILL_ATTEMPT: 'formFillAttempt_L1',
  L1_FORM_SUBMISSION: 'submitBtnClick_L1',
  L2_FORM_FILL_ATTEMPT: 'formFillAttempt_L2',
  L2_FORM_SUBMISSION: 'submitBtnClick_L2',
  PAGE_VIEW_REGISTERED_V2: 'pageViewRegisteredV2',
  L1_SUCCESSFULL_SUBMISSION: 'L1_successfullSubmission',
  L2_SUCCESSFULL_SUBMISSION: 'L2_successfullSubmission',
  L1_UNSUCCESSFULL_SUBMISSION: 'L1_unsuccessfulSubmission',
  L2_UNSUCCESSFULL_SUBMISSION: 'L2_unsuccessfulSubmission',

  //About Us Page Events:
  TOP_STRIP_CTA_CLICK: 'topStripCTAClick',
  LEADERSHIP_VIDEO_CLICK: 'leadershipVideoClick',
  TIMELINE_EVENT_CLICK: 'timelineEventClick',
  TIMELINE_NAVIGATION_BTN_CLICK: 'timelineNavigationBtnClick',
  TIMELINE_MAX_EVENT: 'timelineMaxEvent',
  timelineNavigationBtnClick: 'timelineNavigationBtnClick',

  //Hire from crio page Events:
  HIRE_FROM_CRIO_NO_REC_FORM_SUBMIT: 'hireFromCrioNoRecFormSubmit',
};
