import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import currentConfig from '../Config';
import toast from 'react-hot-toast';
import { initializeApp } from 'firebase/app';

const firebase_config = currentConfig().firebaseConfig;
initializeApp(firebase_config);

// if (!firebase?.apps?.length) {
//   firebase.initializeApp(firebase_config);
// }
const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => useContext(authContext);
// Provider hook that creates auth object and handles state

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = () =>
    signInWithPopup(auth, provider)
      .then(async (response) => {
        setUser(response.user);
        const idToken = await response.user.getIdToken();
        return { status: true, user: response.user, idToken };
      })
      .catch((e) => {
        switch (e.code) {
          case 'auth/network-request-failed':
            toast.error(
              'Authentication failed due to poor network connection. Please refresh your browser and try again.',
              { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
            );
            break;
          case 'auth/popup-blocked':
            toast.error(
              'Your browser has blocked the authentication popup. Please try logging in again or allow popups for this website in browser settings.',
              { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
            );
            break;
          case 'auth/user-disabled':
            toast.error(
              'Authentication failed. Please contact support or administrator for any help.',
              { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
            );
            break;
          case 'auth/web-storage-unsupported':
            toast.error(
              'Your browser has denied session storage access for authentication. Please refresh and try again or switch to a different browser.',
              { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
            );
            break;
          default:
            toast.error(
              'Authentication failed due to an error. Try again or contact support.',
              { duration: 8000, style: { fontSize: '14px' }, id: 'auth' },
            );
            break;
        }
        return { status: false };
      });

  const signout = () =>
    signOut(auth).then(() => {
      setUser(false);
    });

  const getIdToken = async () => {
    if (user) {
      const token = await user?.getIdToken();
      return token;
    }
    return null;
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        const token = await user.getIdToken();
        setToken(token);
      } else {
        setUser(false);
        setToken(false);
        // User is signed out
        // ...
      }
    });

    // checkUser();

    // const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
    //   if (user) {
    //     console.log('useeffect if user is logged in', user);
    //     setUser(user);
    //     const token = await user.getIdToken();
    //     setToken(token);
    //   } else {
    //     console.log('useeffect if user is logged out', user);

    //     setUser(false);
    //     setToken(false);
    //   }
    // });

    // return () => unsubscribe();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    token,
    getIdToken,
    signin,
    signout,
  };
}
