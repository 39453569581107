import DoubleScreenPopupWithMobileAndCouponCode from '@components/DoubleScreenPopupWithMobileAndCouponCode/index';
import SingleScreenPopupWithMobile from '@components/SingleScreenPopupWithMobile/index';
// import "aos/dist/aos.css";
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import React, { Component, useContext, useEffect, useState } from 'react';
import { GTM } from '../../analytics/gtm';
import { gtmEvents } from '../../analytics/gtmEventsConstant';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import './index.scss';
import db from '../../data/db.json';
import { ESale } from '@src/constants/sale/index';
import PhoneInput from 'react-phone-input-2';
import LeadGenerationService from '../../../utils/lead-generation';
import {
  leadStage,
  LEAD_GEN_DEVICE_DESKTOP,
  LEAD_GEN_DEVICE_MOBILE,
  LEAD_GEN_EMAIL_CAPTURE_POPUP,
  LEAD_GEN_PATHNAME_FALLBACK,
  LEAD_GEN_DATA_SOURCE,
  extendLeadObjectWithUserLocationData,
} from '@src/constants/LeadGenerationConstants/index';
import { ELeadSquaredActivityCode } from '@src/constants/leadsquaredActivityConstants/index';
import useResizer from '@components/extra/useResizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import resumeImage from '../../images/popup/ResumeImage.webp';
import curriculumImage from '../../images/popup/CurriculumImage.webp';
import emailCaptureIcon from '../../images/popup/EmailCaptureIcon.webp';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import FormIcon from '../../components/LeadGenerationForm/FormComponents/FormIcon';
import Button from '@components/Button/index';

const Wrapper = ({ children }) => {
  return (
    <span
      style={{
        display: 'block',
      }}
    >
      {children}
    </span>
  );
};
class EmailCapturePopup extends Component {
  static contextType = GlobalStateContext;

  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({ show: false });
    GTM.track(gtmEvents.POPUP_CLOSED, {
      tag: 'EMAIL_CAPTURE_POPUP',
    });
    Cookies.set('email_capture_popup_disable', 'true', { expires: 1 });
  };
  openModal = () => {
    if (
      !this.context.isLeadGenFormOpen &&
      window &&
      window.webengage &&
      window.webengage.util &&
      window.webengage.util.getWebengageCookie() &&
      !window.webengage.util.getWebengageCookie().cuid &&
      !Cookies.get('email_capture_popup_disable')
    ) {
      this.setState({ show: true });
    }
  };
  componentDidMount() {
    window.closeEmailCapture = this.handleClose;
    window.openEmailCapture = this.openModal;
  }

  render() {
    const showHideClassName = this.state.show
      ? `modal modalOpen`
      : `modal modalClose`;

    return (
      <Wrapper>
        <div className={showHideClassName}>
          <section className="modalMain" onClick={(e) => e.stopPropagation()}>
            {this.state.show && (
              <PdfModalContent
                closeModal={this.handleClose}
                sale={this.props.sale}
              />
            )}
          </section>
        </div>
      </Wrapper>
    );
  }
}

function PdfModalContent(props) {
  const location = useLocation();
  const [program, setProgram] = useState({});
  const [variant, setVariant] = useState(1);

  const sale = props.sale;

  const getVariants = () => {
    let availableDialogs = [1, 2];
    const variant =
      availableDialogs[Math.floor(Math.random() * availableDialogs.length)];

    return variant;
  };

  useEffect(() => {
    const pathname = location.pathname;
    const variant = getVariants();
    setVariant(variant);

    //TODO REMOVE THIS HARDCODING
    const freeTrialProgram = db.strapi_metadata.programs.find(
      (ele) => ele.PrimaryProgramID == 'FREE_TRIAL',
    );

    const qaProgram = db.strapi_metadata.programs.find(
      (ele) => ele.PrimaryProgramID == 'QA_AUTOMATION_TESTING',
    );
    if (pathname.includes('/qa-automation-testing')) {
      setProgram(qaProgram);
    } else {
      setProgram(freeTrialProgram);
    }
    GTM.track(gtmEvents.EMAIL_CAPTURE_POPUP_VISIBLE, {
      variant: 'VARIANT_' + variant,
    });
  }, []);

  function getPopup(variant, sale) {
    switch (variant) {
      case 3:
        return (
          <>
            <DoubleScreenPopupWithMobileAndCouponCode
              variant={variant}
              coupon="CRIOGIFT2021"
              closeModal={props.closeModal}
              sale={sale}
            ></DoubleScreenPopupWithMobileAndCouponCode>
          </>
        );
        break;
      case 4:
        return (
          <>
            {
              <SingleScreenPopupWithMobile
                variant={variant}
                title={
                  <>
                    <h4
                      className={`${
                        sale === ESale.DIWALI_SALE
                          ? 'customFont text-diwali-sale-orange-300 mb-4 text-3xl'
                          : 'text-crio-green-200'
                      }`}
                    >
                      Sign up for a free trial.
                    </h4>
                    <h4
                      className={`mb-4 ${
                        sale === ESale.DIWALI_SALE
                          ? 'customFont text-diwali-sale-neutral-300'
                          : 'text-crio-green-700'
                      }`}
                    >
                      Bring your tech career to life!
                    </h4>
                  </>
                }
                description={
                  sale === ESale.DIWALI_SALE ? (
                    <div className={`text-diwali-sale-neutral-300 text-lg`}>
                      {' '}
                      <strong>Build</strong> professional tech-projects,
                      <br className="" />
                      <strong>And</strong> grow your career with real work
                      experience.
                    </div>
                  ) : (
                    <>
                      {' '}
                      <strong>Build</strong> professional tech-projects,
                      <br className="" />
                      <strong>And</strong> grow your career with real work
                      experience.
                    </>
                  )
                }
                closeModal={props.closeModal}
                CTAText={'Book your trial, Now'}
                handleCTASubmit={() => {
                  props.closeModal();
                  navigate('/registration', {
                    state: {
                      link: program.typeform,
                      type: program.PrimaryProgramID,
                      location: 'POPUP_VARIANT_' + variant,
                      program: program,
                    },
                  });
                }}
                sale={sale}
              ></SingleScreenPopupWithMobile>
            }
          </>
        );

      default:
        return (
          <EmailPopup
            sale={sale}
            variant={variant}
            closeModal={props.closeModal}
          />
        );
    }
  }

  return getPopup(variant, sale);
}

const EmailPopup = ({ sale, variant, closeModal }) => {
  const location = useLocation();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const pathName = location?.pathname;
  const isMobile = useResizer();

  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [emailInvalid, setEmailInvalid] = useState(true);
  const [phoneInvalid, setPhoneInvalid] = useState(true);
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const globalState = useContext(GlobalStateContext);
  const userLocation = globalState.userLocation;

  const variants = {
    variant_1: {
      title: 'Want to know how you can land  jobs in product-based companies?',
      content: '',
      image: curriculumImage,
    },
    variant_2: {
      title: (
        <>
          Impress recruiters and crack jobs in top tech companies like Google!
        </>
      ),
      content: '',
      image: resumeImage,
    },

    variant_3: {
      title: '',
      content: '',
    },
    variant_4: {
      title: '',
      content: '',
    },
  };

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        e.target.value,
      )
    ) {
      setEmailInvalid(true);
      setEmail(e.target.value);
      return;
    }

    setEmailInvalid(false);
    setEmail(e.target.value);
  };

  function getCompletion(sale) {
    return (
      <div className="flex h-full w-full items-center justify-center bg-v5-neutral-200">
        <div className="w-4/5 rounded-lg bg-v5-neutral-300 p-4 text-center">
          Yippee! Your free PDF has been mailed to your email account.
        </div>
      </div>
    );
  }

  // sendFocus events only once
  const sendFocusEvents = (type) => {
    if (type === 'PHONE') {
      if (phoneFocus !== true) {
        setPhoneFocus(true);
        GTM.track(gtmEvents.FIELD_FOCUSSED, {
          location: 'EMAIL_CAPTURE_POPUP',
          field: type,
        });
      }
    } else if (type === 'EMAIL') {
      if (emailFocus !== true) {
        setEmailFocus(true);
        GTM.track(gtmEvents.FIELD_FOCUSSED, {
          location: 'EMAIL_CAPTURE_POPUP',
          field: type,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email,
      )
    ) {
      setEmailInvalid(true);
      return;
    }
    GTM.login({
      userId: email,
      email: email,
      phone: phone,
      whatsappNumber: phone,
    });

    const data = {
      email: email,
      phone: phone,
      location: LEAD_GEN_EMAIL_CAPTURE_POPUP,
      applicationStage: leadStage.OTHER_LEAD,
      formURL: pathName || LEAD_GEN_PATHNAME_FALLBACK,
      deviceType: isMobile ? LEAD_GEN_DEVICE_MOBILE : LEAD_GEN_DEVICE_DESKTOP,
      dataSource: LEAD_GEN_DATA_SOURCE,
    };

    // Add user location data
    extendLeadObjectWithUserLocationData(data, userLocation);

    const lead = await LeadGenerationService.generateLead(data);
    const leadID = lead.data.leadID;

    // Set email, phone & docID details in global context
    dispatch({
      type: 'SET_EMAIL',
      payload: data.email,
    });
    GTM.setEmail(data.email);
    dispatch({
      type: 'SET_PHONE',
      payload: data.phone,
    });
    dispatch({
      type: 'SET_GLOBAL_LEAD_DOCUMENT_ID',
      payload: leadID,
    });

    if (process.env.GATSBY_ENV == 'production') {
      fetch(
        `https://script.google.com/macros/s/AKfycbzZu0sfE3ZdlifBokz_l_ei9duEq9YZ3mEN8mf5cDsM8JZyF-2_Et7aiwRW7i7jnQ-6/exec?endpoint=website-leads&email=${email
          .trim()
          .toLowerCase()}&country=${
          state.country
        }&type=EMAIL_CAPTURE_POPUP&location=${
          'VARIANT_' + variant
        }&utm=${encodeURIComponent(state.utm)}&adblocker=${
          typeof window.webengage == 'undefined' ? true : false
        }&url=${window.location.href}`,
      );
    }

    GTM.track(gtmEvents.EMAIL_SUBMITTED, {
      type: 'EMAIL_CAPTURE_POPUP',
      variant: 'VARIANT_' + variant,
    });
    GTM.leadSquaredEvent(
      ELeadSquaredActivityCode.SEND_ME_FREE_PDF_SUBMITTED,
      data.email,
    );

    setEmailInvalid(false);
    setPhoneInvalid(false);
    setEmailSubmitted(true);

    setIsLoading(false);

    !sale &&
      setTimeout(() => {
        closeModal();
      }, 5000);
  };

  return (
    <>
      <div className="emailCapturePopup flex h-full w-[80vw]  min-w-[200px] bg-v5-neutral-200 pb-0 md:max-w-[800px]">
        <div className="hidden h-full w-[260px] bg-v5-neutral-200 text-sm md:block md:text-lg">
          <img
            src={variants['variant_' + variant].image}
            className="h-full w-full object-cover"
            alt={'banner'}
          />
        </div>

        {!emailSubmitted ? (
          <div className="emailCapturePopup-form w-[200px] flex-1 bg-v5-neutral-200 p-4">
            <div>
              <img
                src={emailCaptureIcon}
                className="h-16 w-16 object-contain md:h-24 md:w-24"
                alt={'icon'}
              />
            </div>
            <div className="mt-4 mb-6 text-center text-v5-neutral-600">
              {(!emailSubmitted || !sale) && (
                <h3 className="text-lg">
                  {variants['variant_' + variant].title}
                </h3>
              )}
            </div>
            <div className={`email-field ${emailInvalid && 'error'} w-full `}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div id="my-form">
                  <div className="flex flex-col items-center justify-center gap-2 md:px-8">
                    <div className="relative w-full rounded-2xl border-[1px] border-v5-neutral-300">
                      <input
                        type="email"
                        className="bodyText1 email-input h-[62px] w-full overflow-hidden rounded-2xl p-3 font-light"
                        placeholder="Enter your Email"
                        onChange={handleEmailInput}
                        value={email}
                        required
                        onFocus={() => {
                          sendFocusEvents('EMAIL');
                        }}
                      ></input>
                      <FormIcon
                        icon={
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="text-v5-neutral-100"
                          />
                        }
                        className="bg-v5-neutral-300"
                      />
                    </div>
                    <div className="relative w-full ">
                      <PhoneInput
                        country="in"
                        placeholder="Enter phone number"
                        value={phone}
                        onFocus={() => {
                          sendFocusEvents('PHONE');
                        }}
                        onChange={(newValue) => {
                          setPhone(newValue);

                          if (newValue.startsWith('91')) {
                            const indianRegex = new RegExp('^[6-9][0-9]{9}$');
                            const contactWithoutCountryCode =
                              newValue.substring(2, newValue.length);
                            setPhoneInvalid(
                              !indianRegex.test(contactWithoutCountryCode),
                            );
                          } else {
                            setPhoneInvalid(!(newValue.length >= 8));
                          }
                        }}
                        containerStyle={{
                          width: '100%',
                        }}
                        inputStyle={{
                          width: '100%',
                          border: '1px solid #B9CFCA',
                        }}
                        countryCodeEditable={false}
                      ></PhoneInput>
                      <FormIcon
                        icon={
                          <FontAwesomeIcon
                            icon={faPhone}
                            className="text-v5-neutral-100"
                          />
                        }
                        className="bg-v5-neutral-300"
                      />
                    </div>
                    <div className="flex w-full justify-center">
                      <Button
                        type="submit"
                        className={`natural sm cta flex h-[56px] w-full items-center whitespace-nowrap rounded-[10px] border-2 ${
                          emailInvalid ||
                          phoneInvalid ||
                          !email ||
                          !phone ||
                          isLoading
                            ? 'border-gray-400 bg-gray-400 opacity-50'
                            : 'border-v5-yellow-200 bg-v5-yellow-200 py-4 px-8 font-manrope text-base font-bold capitalize text-black shadow-lg shadow-v5-yellow-200/50  hover:shadow-xl  hover:shadow-v5-yellow-100/50'
                        }`}
                        onClick={(e) => {
                          if (
                            emailInvalid ||
                            phoneInvalid ||
                            !email ||
                            !phone ||
                            isLoading
                          ) {
                            return;
                          }
                          handleSubmit(e);
                        }}
                      >
                        Get free resources NOW!{' '}
                        <FontAwesomeIcon
                          icon={['fas', 'angle-right']}
                          className="ml-2 mt-1 text-lg"
                          style={{ marginBottom: '2px' }}
                        ></FontAwesomeIcon>
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <span className="">{getCompletion(sale)}</span>
        )}
      </div>{' '}
      <span
        onClick={closeModal}
        className="modalCloseIcon right-3 top-3 flex h-6 w-6 items-center justify-center bg-v5-neutral-400 md:h-8 md:w-8"
      >
        <FontAwesomeIcon
          icon={['fal', 'times']}
          className="h-4 w-4 text-v5-green-700 md:h-6 md:w-6"
        ></FontAwesomeIcon>
      </span>
    </>
  );
};

export default EmailCapturePopup;
