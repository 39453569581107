import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import * as styles from './button.module.scss';

import './index.css';

function CustomButton(props) {
  function getButtonStyles(type) {
    // alert(type);
    switch (type) {
      case 'primary':
        return styles.primary;
      case 'secondary':
        return styles.secondary;
      case 'outlined':
        return styles.outlined;
    }
  }
  return (
    <>
      <div
        {...props}
        className={`${props.className} font-bold ${
          styles.button
        } ${getButtonStyles(props.type)} subtitle1 `}
      >
        {props.to ? (
          <Link to={props.to}>{props.children}</Link>
        ) : (
          props.children
        )}
      </div>
    </>
  );
}

const Button = styled(CustomButton)`
  background: ${(props) => props.background} !important;
  color: ${(props) => props.color} !important;
  width: ${(props) => props.width} !important;
  &:hover {
    background: ${(props) => props.hoverbackground} !important;
    color: ${(props) => props.hovercolor} !important;
  }
`;
export default Button;
// import { Link } from 'gatsby';
// import React from 'react';
// import styled from 'styled-components';
// import MuiButton from '@mui/material/Button';

// import * as styles from './button.module.scss';

// import './index.css';

// function Button({ color, variant, ...props }) {
//   function getButtonStyles(type) {
//     // alert(type);
//     switch (type) {
//       case 'primary':
//         return styles.primary;
//       case 'secondary':
//         return styles.secondary;
//       case 'outlined':
//         return styles.outlined;
//     }
//   }
//   return (
//     <>
//       <MuiButton
//         {...props}
//         color={color || 'primary'}
//         variant={variant || 'contained'}
//         className={`shadow-none ${props.className} capitalize font-bold ${
//           styles.button
//         } ${getButtonStyles(props.type)} subtitle1 `}
//       >
//         {props.to ? (
//           <Link to={props.to}>{props.children}</Link>
//         ) : (
//           props.children
//         )}
//       </MuiButton>
//     </>
//   );
// }

// // const Button = styled(CustomButton)`
// //   background: ${(props) => props.background} !important;
// //   color: ${(props) => props.color} !important;
// //   width: ${(props) => props.width} !important;
// //   &:hover {
// //     background: ${(props) => props.hoverbackground} !important;
// //     color: ${(props) => props.hovercolor} !important;
// //   }
// // `;
// export default Button;
